<template>
  <div>
    <cy-head />
    <!-- <cy-banner @closeBanner="closeBanner()"></cy-banner> -->
    <cy-nav
      :is-banner-open="showTopBanner"
      @subscribeClick="onClickSubscribe()"
      @showBackdrop="checkBackdrop"
    />
    <main
      :class="{
        'cy-bg-overlay': showBackdrop,
        'cy-container--padding-top': showTopBanner && $route.path === '/'
      }"
      role="main"
      class="cy-container"
    >
      <section class="dashboard">
        <b-container fluid>
          <b-row>
            <b-col md="3" class="dashboard--left">
              <cy-dashboard-sidebar />
            </b-col>
            <b-col md="9" class="dashboard--right">
              <nuxt />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- <cy-request-demo-form /> -->
      <cy-profile />
      <cy-footer :class="showBackdrop ? 'cy-bg-overlay__fade bg-white' : ''" />
      <cy-subscriber-modal
        :from-footer="fromFooter"
        @fromFooter="fromFooter = false"
      />
    </main>
  </div>
</template>

<script>
import CyNav from '@/components/shared/Nav'
import CyHead from '@/components/shared/Head'
export default {
  components: {
    CyHead,
    CyNav,
    CyDashboardSidebar: () => import('@/components/dashboard/DashboardSidebar'),
    CyFooter: () => import('@/components/shared/Footer'),
    CyProfile: () => import('@/components/shared/ProfilePopup'),
    CySubscriberModal: () =>
      import('@/components/miscellaneous/CySubscriberModal')
  },
  data() {
    return {
      fromFooter: false,
      showTopBanner: false,
      showBackdrop: false
    }
  },
  mounted() {},
  methods: {
    closeBanner() {
      this.showTopBanner = false
    },
    onClickSubscribe() {
      this.fromFooter = true
    },
    checkBackdrop(evt) {
      this.showBackdrop = evt
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard {
  background: #fafafa;
}
</style>
