import Vue from 'vue';

import {
  LayoutPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin,
  ToastPlugin,
  VBPopoverPlugin,
  VBTooltipPlugin,
  VBTogglePlugin
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(PopoverPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(ToastPlugin);

Vue.use(VBPopoverPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(VBTogglePlugin);
