// export default function ({ route, redirect, $config }) {
//   if (
//     route.path === '/request-a-demo' ||
//     (route.meta && route.meta[0] && route.meta[0].slug === 'demo-page')
//   ) {
//     const demoUrl = $config.demoUrl
//     if (!process.client) {
//       return redirect(demoUrl)
//     } else {
//       window.location.replace(demoUrl)
//     }
//   }
// }

export default function ({ route, redirect, $config }) {
  if (
    route.path === '/request-a-demo' ||
    (route.meta && route.meta[0] && route.meta[0].slug === 'demo-page')
  ) {
    const demoUrl = $config.demoUrl
    if (!process.client) {
      // Server-side redirection
      return redirect(demoUrl)
    } else {
      // Client-side redirection
      return redirect(demoUrl)
        .then(() => {
          return this.$router.push(demoUrl)
        })
        .catch((err) => {
          console.error('Client-side redirection failed:', err)
        })
    }
  }
}
