/* eslint-disable prefer-regex-literals */
import {
  required,
  email,
  regex,
  alpha,
  max,
  numeric,
  confirmed
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { EMAIL_PROVIDERS } from '@/config/email-providers'

const IPV4 =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

// eslint-disable-next-line no-useless-escape
const URL =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/

// eslint-disable-next-line no-useless-escape
const PHONEREGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,12}$/

const CONTACTNUMBER = /^\+[1-9]{1}[0-9]{7,11}$/

const ORGANIZATIONNAME = /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/

const INDUSTRYNAME = /^[ A-Za-z0-9&./,-;]*$/

const COUNTRYCODE = /^\+[1-9]{2}[0-9]$/

const ALPHANUMWITHSPACES = /^[a-zA-Z0-9 ]*$/

const alphawithspaces = {
  getMessage: (field) => `Only alphanumeric characters are allowed`,
  validate(value) {
    const pattern = new RegExp(ALPHANUMWITHSPACES)
    return pattern.test(value)
  }
}
const phone = {
  getMessage: (field) => `Please enter a valid phone number`,
  validate(value) {
    const pattern = new RegExp(PHONEREGEX)
    return pattern.test(value)
  }
}

const countryCode = {
  getMessage: (field) => `Invalid Code`,
  validate(value) {
    const pattern = new RegExp(COUNTRYCODE)
    return pattern.test(value)
  }
}

const emailDomainValidation = {
  validate(value) {
    const splitArray = value.split('@')
    if (EMAIL_PROVIDERS.includes(splitArray[1])) {
      return false
    } else {
      return true
    }
  }
}

const IPv4Check = {
  validate(value) {
    const pattern = new RegExp(IPV4)
    return pattern.test(value)
  }
}

const URLCheck = {
  validate(value) {
    const pattern = new RegExp(URL)
    return pattern.test(value)
  }
}

const contactNumber = {
  getMessage: (field) =>
    `Enter a valid contact number in the format +[Country Code] [Phone Number]`,
  validate(value) {
    const pattern = new RegExp(CONTACTNUMBER)
    return pattern.test(value.country_code + value.ph_number)
  }
}

const OragnizationName = {
  validate(value) {
    const pattern = new RegExp(ORGANIZATIONNAME)
    return pattern.test(value)
  }
}

const IndustryName = {
  validate(value) {
    const pattern = new RegExp(INDUSTRYNAME)
    return pattern.test(value)
  }
}

extend('url', {
  ...URLCheck,
  message: 'This field must be a valid url'
})

extend('country_code', {
  ...countryCode,
  message: countryCode.getMessage
})

extend('phone', {
  ...phone,
  message: phone.getMessage
})

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'Enter a valid email address.'
})

extend('emailDomainValidation', {
  ...emailDomainValidation,
  message: 'Only business emails are allowed'
})

extend('IPv4Check', {
  ...IPv4Check,
  message: 'Please enter valid IP Address'
})

extend('max', {
  ...max,
  message: 'This field has character limit'
})

extend('regex', {
  ...regex,
  message: 'Please match the format requested'
})

extend('numeric', {
  ...numeric,
  message: 'This field must be numeric only'
})

extend('confirmed', {
  ...confirmed,
  message: 'Password does not match'
})

extend('alpha', {
  ...alpha,
  message: 'Only alphabetic characters are allowed'
})

extend('contact_number', {
  ...contactNumber,
  message:
    'Enter a valid contact number in the format +[Country Code] [Phone Number]'
})

extend('industry_name', {
  ...IndustryName,
  message:
    'Only alphanumeric and the following special characters (& . / , - ;) are allowed'
})

extend('organization_name', {
  ...OragnizationName,
  message: 'Only alphanumeric and special characters are allowed'
})

extend('alpha_spaces', {
  ...alphawithspaces,
  message: 'Only alphanumeric characters are allowed'
})
