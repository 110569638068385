var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {}, [_c('b-container', [_c('b-row', {
    staticClass: "h-100 align-items-center"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "cy-heading-2 cy-text-nile-blue cy-line-height-40 cy-font-weight-800"
  }, [_vm._v("\n            404 ERROR\n          ")]), _vm._v(" "), _c('h2', {
    staticClass: "cy-heading-2 cy-text-nile-blue cy-line-height-40"
  }, [_vm._v("\n            Looks like this page is missing or no longer available. "), _c('br'), _vm._v("Keep\n            calm and find your way back "), _c('nuxt-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("home")]), _vm._v(".\n          ")], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-img', {
    attrs: {
      "src": "/not-found.png"
    }
  })], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }