<template>
  <div class="cy-navbar__dropdown">
    <div class="cy-navbar__dropdown-content">
      <div
        class="
          cy-navbar__dropmenu cy-navbar__dropmenu--padding
          d-flex
          align-items-center
        "
      >
        <div v-if="userDetails && userDetails.user_image_url" class="pr-3">
          <img
            :src="userDetails.user_image_url"
            class="cy-navbar__profile-img"
          />
        </div>
        <div v-else class="pr-3">
          <img
            src="~/static/dp-placeholder.svg"
            class="cy-navbar__profile-img"
          />
        </div>
        <div v-if="userDetails" class="cy-word-break cy-w-250">
          <span class="d-block cy-navbar__profile-name mb-1"
            >{{ userDetails.first_name }}
          </span>
          <span class="d-block cy-navbar__profile-mail">
            {{ userDetails.email }}
          </span>
          <a
            :style="{ color: '#007bff !important', fontSize: '14px' }"
            class="dropdown--link cursor-pointer"
            @click="profilePopup()"
          >
            My Profile
          </a>
        </div>
      </div>
      <ul class="list-unstyled cy-navbar__dropmenu">
        <li>
          <nuxt-link
            to="/dashboard/subscriptions"
            class="dropdown--link"
            @click.native="$emit('close')"
          >
            My Subscriptions
          </nuxt-link>
        </li>
        <!-- <li>
          <nuxt-link
            @click.native="$emit('close')"
            :to="getHyperLink('ctix-lite')"
            class="dropdown--link"
          >
            eXchange Lite
          </nuxt-link>
        </li> -->
        <li>
          <nuxt-link
            to="/dashboard/threat-intel-feeds"
            class="dropdown--link"
            @click.native="$emit('close')"
          >
            Cyware Threat Intel Feeds
          </nuxt-link>
        </li>
      </ul>
      <ul class="list-unstyled cy-navbar__dropmenu">
        <li>
          <a
            href="/cyber-security-news-articles"
            target="_blank"
            class="dropdown--link"
            >Go to Cyware Social
            <i class="cyicon-external-link text-primary ml-4"></i
          ></a>
        </li>
      </ul>
      <ul class="list-unstyled cy-navbar__dropmenu">
        <li>
          <a class="dropdown--link" @click="logout">Log out</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    logout() {
      const authToken = this.$cookies.get('auth._token.local')
      if (authToken) {
        this.$axios.setHeader('Authorization', authToken)
        this.$axios
          .$get(
            `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/logout`
          )
          .then((response) => {
            this.$store.commit('resetAuthentication')
            this.makeToast(
              'success',
              'You have been successfully logged out',
              'You have been successfully logged out',
              'b-toaster-top-center'
            )
            window.location.href = '/'
          })
          .catch((error) => {
            this.makeToast('danger', 'My Profile', error.response.data.detail)
          })
          .finally(() => {
            // always executed
          })
      } else {
        window.location = '/login?source=enterprise'
      }
      // this.$store.commit('resetAuthentication')
      // this.makeToast(
      //   'success',
      //   'You have been successfully logged out',
      //   'You have been successfully logged out',
      //   'b-toaster-top-center'
      // )
      // window.location.href = '/'
    },
    profilePopup() {
      this.$emit('show')
    }
  }
}
</script>
