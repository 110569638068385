<template>
  <b-navbar
    id="cy-navbar"
    ref="navbar"
    :class="[isBannerOpen && $route.path === '/' ? 'cy-mt-40' : '']"
    class="cy-navbar fixed-top cy-header-white"
  >
    <b-container class="position-relative py-2 pr-md-3">
      <cy-mobile-menu
        v-if="$device.isMobile && $device.isMobileOrTablet"
        :is-banner-open="isBannerOpen"
        @toggle="activeMenu = $event"
      />
      <div>
        <b-navbar-brand class="mr-0 mr-md-3">
          <nuxt-link to="/" class="d-inline-block">
            <b-img
              v-if="homeLogo"
              id="cyware-home-logo"
              :src="require(`~/static/logos/${homeLogo}.svg`)"
              fluid
              class="logo-height desktop-logo"
              alt="Cyware Logo"
              :width="200"
              :height="35"
            />
          </nuxt-link>
        </b-navbar-brand>
      </div>
      <div
        v-if="$device.isDesktop"
        class="d-flex flex-grow-1 align-items-center"
      >
        <b-navbar-nav v-if="!showSearch">
          <li
            v-for="(item, index) in Object.keys(menus).filter(
              (item) => item !== 'extra' && item !== 'learn_more'
            )"
            :key="index"
          >
            <nuxt-link
              v-if="menus[item].headerimage"
              role="button"
              class="nav-link ml-3"
              :to="menus[item].link"
            >
              <b-img-lazy :src="menus[item].headerimage" alt="cywareai">
              </b-img-lazy>
            </nuxt-link>
          </li>
          <li
            v-for="(item, index) in Object.keys(menus).filter(
              (item) => item !== 'extra' && item !== 'learn_more'
            )"
            :key="index"
            class="nav-item cy-font-weight-600 position-relative"
            @mouseenter="$emit('showBackdrop', true)"
            @mouseleave="$emit('showBackdrop', false)"
          >
            <a
              v-if="!menus[item].headerimage"
              role="button"
              class="nav-link mt-1"
            >
              {{ menus[item].title }}
            </a>
            <cy-main-menu
              v-if="!menus[item].headerimage"
              :id="item"
              :slug="item"
              :menu="menus[item]"
              @click="collapseNav"
              @footerSubscribe="onClickSubscribe()"
            />
          </li>
        </b-navbar-nav>

        <b-navbar-nav
          v-click-outside="onClickOutside"
          class="cy-right-navbar ml-auto align-items-center"
        >
          <li
            v-if="!showSearch"
            class="nav-item cy-font-weight-600 cy-text-primary-blue"
          >
            <nuxt-link
              class="nav-link ml-4 cy-home-header__blogs"
              :to="getHyperLink('blog')"
            >
              Blogs
            </nuxt-link>
          </li>
          <li
            class="nav-item cy-global-search d-inline-flex align-items-center"
          >
            <b-form-input
              v-if="showSearch"
              ref="globalSearch"
              placeholder="Search for blogs, briefings, security guides, cyber news & more…"
              @keyup.enter="onEnterSearch($event)"
            />
            <span
              class="mx-2 cy-global-search__icon"
              :class="
                showSearch
                  ? 'cyicon-close cy-heading-6'
                  : 'cyicon-search_icon cy-heading-4'
              "
              @click="toggleSearch()"
            />
          </li>
          <li v-if="isAuthenticated" class="nav-item position-relative my-auto">
            <a class="nav-link">
              <div v-if="userDetails && userDetails.user_image_url">
                <b-img-lazy
                  :src="userDetails.user_image_url"
                  class="nav-profile-image"
                />
              </div>
              <div
                v-else
                :style="{
                  border: '1px solid darkgray',
                  borderRadius: '50px'
                }"
              >
                <b-img-lazy
                  src="~/static/dp-placeholder.svg"
                  class="nav-profile-image"
                />
              </div>
            </a>
            <cy-profile-dropdown @show="showProfilePopup()" />
          </li>
          <li
            v-else
            class="nav-item cy-font-weight-600 my-auto cy-text-primary-blue"
          >
            <a
              :href="`/login?source=enterprise${
                currentLocation ? '&next=' + currentLocation : ''
              }`"
              class="nav-link"
              >Login</a
            >
          </li>
          <li class="nav-item">
            <nuxt-link
              :to="getHyperLink('demo-page')"
              class="text-decoration-none cy-text-white"
            >
              <b-button type="button" class="cy-btn"> Request a Demo </b-button>
            </nuxt-link>
          </li>
        </b-navbar-nav>
      </div>
      <ul class="d-lg-none d-md-none list-unstyled mb-0">
        <li
          v-if="isAuthenticated"
          :class="{ active: showProfileDropdown }"
          class="nav-item position-relative my-auto cy-pl-xs-0"
        >
          <a
            class="nav-link cy-pl-xs-0 cy-pr-xs-0 cy-pt-xs-0 cy-pb-xs-0"
            @click="showProfileDropdown = !showProfileDropdown"
          >
            <div v-if="userDetails && userDetails.user_image_url">
              <b-img-lazy
                :src="userDetails.user_image_url"
                class="nav-profile-image"
              />
            </div>
            <div
              v-else
              :style="{
                border: '1px solid darkgray',
                borderRadius: '50px'
              }"
            >
              <b-img-lazy src="/dp-placeholder.svg" class="nav-profile-image" />
            </div>
          </a>
          <cy-profile-dropdown
            @show="showProfilePopup()"
            @click.native="showProfileDropdown = false"
            @close="showProfileDropdown = false"
          />
        </li>
        <li
          v-else
          class="nav-item cy-font-weight-600 my-auto cy-pl-xs-0 cy-text-primary-blue"
        >
          <a
            :href="`/login?source=enterprise${
              currentLocation ? '&next=' + currentLocation : ''
            }`"
            class="nav-link cy-pl-xs-0 cy-pr-xs-0 cy-pt-xs-0 cy-pb-xs-0 cy-mobile-menu__toggler"
            >Login</a
          >
        </li>
      </ul>
    </b-container>
    <cy-profile v-if="showProfileModal && isAuthenticated" ref="cyprofile" />
  </b-navbar>
</template>

<script>
import CyMainMenu from './MainMenu'
import CyProfileDropdown from './ProfileDropdown'
import CyProfile from './ProfilePopup'
const menus = require('@/config/menuData.js')
export default {
  components: {
    CyMainMenu,
    CyProfileDropdown,
    CyProfile,
    CyMobileMenu: () => import('@/components/shared/MobileMenu.vue')
  },
  props: {
    isBannerOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false,
      showProfileDropdown: false,
      activeMenu: false,
      currentLocation: null,
      showProfileModal: false,
      showSearch: false,
      homeLogo: 'cyware-logo'
    }
  },

  computed: {
    menus() {
      return menus
    }
  },
  watch: {
    // $route(newVal) {
    //   if (newVal) {
    //     this.currentLocation = newVal.path !== '/' ? `${newVal.path}` : null
    //     this.homeLogo =
    //       newVal.path === '/' ? 'cyware-logo-white' : 'cyware-logo'
    //     this.activeMenu = !(newVal.path === '/')
    //   }
    // },
    showSearch(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.globalSearch.focus()
        })
      }
    }
    // activeMenu(val) {
    //   this.homeLogo =
    //     val === false && this.$route.path === '/'
    //       ? 'cyware-logo-white'
    //       : 'cyware-logo'
    // }
  },
  created() {
    // if (this.$route.path === '/') {
    //   this.homeLogo = 'cyware-logo-white'
    // } else {
    //   this.homeLogo = 'cyware-logo'
    // }
  },
  mounted() {
    this.currentLocation = this.$route.path !== '/' ? this.$route.path : null
  },
  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch
    },
    onClickOutside() {
      this.showSearch = false
    },
    onEnterSearch(event) {
      this.$router.push({
        path: '/enterprise-search',
        query: { query: event.target.value }
      })
    },
    // changeNavBg(event) {
    //   const header = document.getElementById('cy-navbar')
    //   if (this.$route.path === '/' && event.type === 'mouseenter') {
    //     header.classList.remove('cy-home-header')
    //     header.classList.add('cy-header-white')
    //     this.homeLogo = `cyware-logo`
    //   } else if (this.$route.path === '/' && event.type === 'mouseleave') {
    //     header.classList.remove('cy-header-white')
    //     header.classList.add('cy-home-header')
    //     this.homeLogo = `cyware-logo-white`
    //   }
    // },

    collapseNav(slug) {
      const headerMenu = document.getElementById(slug)
      if (headerMenu) {
        headerMenu.classList.add('d-none')
        setTimeout(() => {
          const headerMenu = document.getElementById(slug)
          headerMenu.classList.remove('d-none')
        }, 1000)
      }
    },
    showProfilePopup() {
      this.showProfileModal = true
      setTimeout(() => {
        this.$refs.cyprofile.show()
      }, 100)
    },
    onClickSubscribe() {
      this.$emit('subscribeClick')
    }
  }
}
</script>
