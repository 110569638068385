var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app-layout"
    }
  }, [_c('cy-head'), _vm._v(" "), _vm.showTopBanner && _vm.$route.path === '/' ? _c('cy-banner', {
    attrs: {
      "data": _vm.bannerData['top-toast'] && _vm.bannerData['top-toast'][0]
    },
    on: {
      "closeBanner": function ($event) {
        return _vm.closeBanner();
      }
    }
  }) : _vm._e(), _vm._v(" "), !_vm.hideHeaderFooter ? _c('cy-nav', {
    attrs: {
      "is-banner-open": _vm.showTopBanner
    },
    on: {
      "subscribeClick": function ($event) {
        return _vm.onClickSubscribe();
      },
      "showBackdrop": _vm.checkBackdrop
    }
  }) : _vm._e(), _vm._v(" "), _c('main', {
    staticClass: "cy-container",
    class: {
      'cy-bg-overlay': _vm.showBackdrop,
      'cy-container--padding-top': _vm.showTopBanner && _vm.$route.path === '/'
    },
    attrs: {
      "role": "main"
    }
  }, [_c('nuxt'), _vm._v(" "), _c('cy-subscriber-modal', {
    attrs: {
      "from-footer": _vm.fromFooter
    },
    on: {
      "fromFooter": function ($event) {
        _vm.fromFooter = false;
      }
    }
  }), _vm._v(" "), !_vm.hideHeaderFooter ? _c('cy-footer') : _vm._e()], 1), _vm._v(" "), _vm.showScrollTop ? _c('div', {
    staticClass: "position-fixed bottom-right text-center"
  }, [_c('a', {
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require("static/go_to_top.svg")
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-6 mb-0"
  }, [_vm._v("Go to top")])], 1)]) : _vm._e(), _vm._v(" "), _c('cy-gdpr')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }