<template>
  <b-modal
    id="profile-modal"
    ref="profileModal"
    centered
    size="lg"
    content-class="cy-profile"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    @close="triggerEditmode(false)"
  >
    <div v-if="userDetails" class="profile">
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <b-container fluid class="pl-0 pr-0 pr-md-5">
          <b-row class="profile--info" no-gutters>
            <b-col cols="12" md="4" class="profile--info__left">
              <div v-if="userDetails" class="profile--details">
                <div class="profile--details__avatar">
                  <img
                    :src="
                      imgPreview ||
                      userDetails.user_image_url ||
                      `/dp-placeholder.svg`
                    "
                  />
                  <a
                    v-if="editMode"
                    class="upload-btn"
                    @click="triggerImageUpload()"
                  >
                    <img src="/camera.svg" />
                  </a>
                  <input
                    ref="imgUpload"
                    type="file"
                    accept="image/*"
                    hidden
                    @change="onImageChange"
                  />
                </div>
                <div class="profile--details__name mt-3">
                  <span v-if="editMode">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ alpha: true }"
                      name="FirstName"
                      vid="FirstName"
                    >
                      <input
                        v-model="userModel.first_name"
                        class="profile--input name-input m-0 px-4 w-100"
                        placeholder="Your Name"
                      />
                      <b-form-invalid-feedback
                        id="inputLiveFeedback"
                        :class="{
                          'd-inline-block w-100 text-center cy-heading-6':
                            errors.length > 0
                        }"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </span>
                  <span v-else>{{ userDetails.first_name }}</span>
                </div>
                <div class="profile--details__designation">
                  <span v-if="editMode">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ alpha_spaces: true }"
                      name="JobTittle"
                      vid="JobTittle"
                    >
                      <input
                        v-model="userModel.job_title"
                        class="profile--input job-input m-0 px-4 w-100"
                        placeholder="Your Job Title"
                      />
                      <b-form-invalid-feedback
                        id="inputLiveFeedback"
                        :class="{
                          'd-inline-block w-100 text-center cy-heading-6':
                            errors.length > 0
                        }"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </span>
                  <span v-else>{{ userDetails.job_title }}</span>
                </div>
                <div class="profile--details__social mt-2">
                  <span
                    :class="userDetails.fb_url || editMode ? '' : 'notAllowed'"
                  >
                    <a
                      :class="
                        userDetails.fb_url || editMode ? '' : 'disableInput'
                      "
                      target="_blank"
                      @click="
                        triggerSocialInput(editMode, 1, userDetails.fb_url)
                      "
                    >
                      <i class="cyicon-facebook"></i>
                    </a>
                  </span>
                  <span
                    :class="
                      userDetails.linkedin_url || editMode ? '' : 'notAllowed'
                    "
                  >
                    <a
                      :class="
                        userDetails.linkedin_url || editMode
                          ? ''
                          : 'disableInput'
                      "
                      target="_blank"
                      @click="
                        triggerSocialInput(
                          editMode,
                          2,
                          userDetails.linkedin_url
                        )
                      "
                    >
                      <i class="cyicon-linkedin"></i>
                    </a>
                  </span>
                  <span
                    :class="
                      userDetails.twitter_url || editMode ? '' : 'notAllowed'
                    "
                  >
                    <a
                      :class="
                        userDetails.twitter_url || editMode
                          ? ''
                          : 'disableInput'
                      "
                      target="_blank"
                      @click="
                        triggerSocialInput(editMode, 3, userDetails.twitter_url)
                      "
                    >
                      <i class="cyicon-twitter"></i>
                    </a>
                  </span>
                </div>
                <div
                  v-if="editMode && activeIndex"
                  class="profile--details__socialinput mt-3 px-4 w-100"
                >
                  <div class="py-1 link-input">
                    <div v-if="activeIndex === 1" class="btn-group">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ url: true }"
                        name="FacebookURL"
                        vid="FacebookURL"
                      >
                        <i class="cyicon-facebook px-2 my-1"></i>
                        <input
                          v-model="userModel.fb_url"
                          class="pl-2"
                          placeholder="Your Facebook Profile"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </div>
                    <div v-if="activeIndex === 2" class="btn-group">
                      <i class="cyicon-linkedin px-2 my-1"></i>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ url: true }"
                        name="LinkedURL"
                        vid="LinkedURL"
                      >
                        <input
                          v-model="userModel.linkedin_url"
                          class="pl-2"
                          placeholder="Your Linkedin Profile"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </div>
                    <div v-if="activeIndex === 3" class="btn-group">
                      <i class="cyicon-twitter px-2 my-1"></i>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ url: true }"
                        name="TwitterURL"
                        vid="TwitterURL"
                      >
                        <input
                          v-model="userModel.twitter_url"
                          class="pl-2"
                          placeholder="Your Twitter Profile"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </div>
                  </div>
                  <div class="mt-1 link-action btn-group">
                    <button @click="passes(submit)">
                      <i class="cyicon-check confirm"></i>
                    </button>
                    <button class="ml-2" @click="cancelLinkEdit()">
                      <i class="cyicon-close cancel"></i>
                    </button>
                  </div>
                </div>
                <div class="profile--details__live text-center">
                  Active Since -
                  {{
                    (userDetails.active_since * 1000) | formattedDate('partial')
                  }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="8" class="profile--info__right">
              <div v-if="userDetails" class="profile--moreinfo">
                <div class="info-flex">
                  <div>
                    <h5>Email</h5>
                    <span v-if="editMode">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ email: true }"
                        name="Email"
                        vid="Email"
                      >
                        <input
                          v-model="userModel.email"
                          class="profile--input m-0 p-0"
                          placeholder="Your Email"
                          disabled
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </span>
                    <p v-else class="m-0 p-0">
                      {{ userDetails.email }}
                    </p>
                  </div>
                  <div>
                    <h5>Organization</h5>
                    <span v-if="editMode">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ organization_name: true }"
                        name="Organization"
                        vid="Organization"
                      >
                        <input
                          v-model="userModel.company"
                          class="profile--input m-0 p-0"
                          placeholder="Organization Name"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </span>
                    <p v-else class="m-0 p-0">
                      {{ userDetails.company ? userDetails.company : '-' }}
                    </p>
                  </div>
                </div>
                <div class="info-flex">
                  <div>
                    <h5>Industry</h5>
                    <span v-if="editMode">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ industry_name: true }"
                        name="Industry"
                        vid="Industry"
                      >
                        <input
                          v-model="userModel.industry"
                          class="profile--input m-0 p-0"
                          placeholder="Industry Name"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </span>
                    <p v-else class="m-0 p-0">
                      {{ userDetails.industry ? userDetails.industry : '-' }}
                    </p>
                  </div>
                  <div>
                    <h5>Experience in Cybersecurity Role</h5>
                    <span v-if="editMode">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ numeric: true, max: 3 }"
                        name="Experience"
                        vid="Experience"
                      >
                        <input
                          v-if="editMode"
                          v-model="userModel.job_experience"
                          class="profile--input m-0 p-0"
                          placeholder="Your Experience"
                          maxlength="3"
                        />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </span>
                    <p v-else class="m-0 p-0">
                      {{
                        userDetails.job_experience
                          ? userDetails.job_experience
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
                <div class="w-100 px-5"><hr /></div>
                <div class="w-100 pl-3 py-4 pl-md-5 text-left">
                  Personal Information
                </div>
                <div class="info-flex">
                  <div>
                    <h5>Contact Number</h5>
                    <div v-if="editMode" class="btn-group w-100">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          contact_number: true
                        }"
                        name="ContactNumber"
                        vid="ContactNumber"
                      >
                        <contact-number v-model="userModel" />
                        <b-form-invalid-feedback
                          id="inputLiveFeedback"
                          :class="{
                            'd-inline-block w-100': errors.length > 0
                          }"
                          >{{ errors[0] }}</b-form-invalid-feedback
                        >
                      </validation-provider>
                    </div>
                    <p v-else class="m-0 p-0">
                      {{
                        `${
                          userDetails.country_code
                            ? userDetails.country_code
                            : '-'
                        } / ${
                          userDetails.ph_number ? userDetails.ph_number : '-'
                        }`
                      }}
                    </p>
                  </div>
                  <div>
                    <h5>Country/Regions</h5>
                    <div v-if="editMode" class="position-relative">
                      <select
                        v-model="userModel.country_iso"
                        :class="{
                          'apply-placeholder': !userModel.country_iso
                        }"
                        class="profile--input w-100 p-0"
                        placeholder="Country Name"
                      >
                        <option value="" disabled>Select Your Country</option>
                        <option
                          v-for="(country, index) in countryList"
                          :key="index"
                          :value="country.iso"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                      <i
                        :style="{
                          top: '7px',
                          right: '-20px',
                          fontSize: '12px',
                          color: '#7f7f7f'
                        }"
                        class="icon icon-chevron-down position-absolute"
                      ></i>
                    </div>
                    <p v-else class="m-0 p-0">
                      {{
                        userDetails.country_iso
                          ? getCountryName(userDetails.country_iso)
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="profile--action">
                <span v-if="editMode">
                  <a
                    class="profile--action__btn secondary"
                    @click="
                      editMode = !editMode
                      triggerEditmode(false)
                    "
                  >
                    Cancel</a
                  >
                  <button
                    :disabled="submitAction"
                    class="profile--action__btn cy-btn cy-btn--primary submit px-1"
                    @click="passes(submit)"
                  >
                    <div class="d-flex align-self-center">
                      Save Profile&nbsp;
                      <b-spinner
                        v-if="submitAction"
                        small
                        class="mt-1"
                        type="grow"
                      >
                      </b-spinner>
                    </div>
                  </button>
                </span>
                <span v-else>
                  <a
                    class="profile--action__btn cy-btn cy-btn--primary submit px-1"
                    @click="
                      editMode = !editMode
                      triggerEditmode(true)
                    "
                  >
                    Edit Profile</a
                  >
                </span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </ValidationObserver>
    </div>
    <div v-else class="text-center">
      <h2 class="cy-heading-2 cy-text-nile-blue cy-line-height-40">
        Something went wrong. Please refresh the page.
      </h2>
    </div>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ContactNumber from './ContactNumber.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ContactNumber
  },
  data() {
    return {
      editMode: false,
      userModel: {},
      imgPreview: false,
      submitAction: false,
      formFields: [
        'first_name',
        'country_code',
        'ph_number',
        'job_title',
        'industry',
        'company',
        'job_experience',
        'fb_url',
        'linkedin_url',
        'twitter_url',
        'user_image',
        'country_iso'
      ],
      activeIndex: 0
    }
  },
  computed: {
    userDetails() {
      return this.$store.state.list.USER_PROFILE
    },
    countryList() {
      return this.$store.state.list.COUNTRY_LIST
    }
  },
  created() {
    if (!this.countryList) {
      this.getCountryList()
    }
  },
  methods: {
    getCountryList() {
      this.$axios
        .$get(
          `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/country-list`
        )
        .then((res) => {
          const list = [...res.results]
          this.$store.commit('makeData', {
            reference: 'COUNTRY_LIST',
            data: list
          })
        })
    },
    show() {
      this.$refs.profileModal.show()
    },
    getCountryName(iso) {
      let itemIndex = -1
      if (this.countryList) {
        itemIndex = this.countryList
          .map((i) => {
            return i.iso
          })
          .indexOf(iso)
      }
      return itemIndex !== -1 ? this.countryList[itemIndex].name : '-'
    },
    triggerEditmode(fill) {
      this.userModel = fill ? Object.assign({}, this.userDetails) : {}
      this.formFields.forEach((field) => {
        if (!this.userModel[field]) {
          this.userModel[field] = ''
        }
      })
      if (!this.userModel.country_iso) {
        this.userModel.country_iso = ''
      }
      if (!this.userModel.user_image) {
        delete this.userModel.user_image
      }
      this.editMode = fill
    },
    triggerImageUpload() {
      this.$refs.imgUpload.click()
    },
    onImageChange(e) {
      const files = e.target.files
      if (files && files.length & (files[0].size < 5000000)) {
        this.userModel.user_image = files[0]
        this.imgPreview = URL.createObjectURL(files[0])
      }
    },
    triggerSocialInput(edit, index, url) {
      if (edit) {
        this.activeIndex = index
      } else {
        window.open(url, '_blank')
      }
    },
    cancelLinkEdit() {
      this.activeIndex = 0
    },
    submit() {
      this.submitAction = true
      const formData = new FormData()
      for (const key in this.userModel) {
        if (this.formFields.includes(key)) {
          formData.append(key, this.userModel[key])
        }
      }
      const authToken = this.$cookies.get('auth._token.local')
      if (authToken) {
        this.$axios.setHeader('Authorization', authToken)
        this.$axios
          .$post(
            `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/user-profile-details`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          )
          .then((response) => {
            this.fetchUserProfile()
            this.makeToast(
              'success',
              'My Profile',
              'Profile is updated successfully!'
            )
            this.editMode = !this.editMode
            this.submitAction = false
          })
          .catch((error) => {
            this.makeToast('danger', 'My Profile', error.response.data.detail)
            this.submitAction = false
          })
          .finally(() => {
            // always executed
          })
      } else {
        window.location = '/login?source=enterprise'
      }
    },
    async fetchUserProfile() {
      const response = await this.$axios.$get(
        `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/user-profile-details`
      )
      this.$store.commit('makeData', {
        reference: 'USER_PROFILE',
        data: response
      })
    }
  }
}
</script>
<style lang="scss">
.cy-profile {
  &.modal-content {
    border: 0;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .modal-header {
    border: 0;
    padding: 0;
    height: 0;
    .close {
      background: #ffffff;
      color: #8993a4;
      margin: 8px 8px 0 0;
      padding: 8px 12px;
      transition: color 300ms ease-in;
      z-index: 100;
      &:hover {
        color: #5e6c84;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  .modal-body {
    padding: 0;
    border: 0;
  }
  .profile {
    &--info {
      height: 585px;
      &__left {
        background: #1968fc;
        word-break: break-word;
        height: inherit;
        .profile--details {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          height: inherit;
          &__avatar {
            height: 200px;
            width: 200px;
            position: relative;
            img {
              border-radius: 200px;
              height: inherit;
              width: inherit;
            }
            .upload-btn {
              position: absolute;
              top: 5px;
              right: 30px;
              cursor: pointer;
              img {
                background: #1968fc;
                width: 24px;
                height: 24px;
                border: 1px solid #ffffff;
                border-radius: 24px;
                padding: 4px;
                height: inherit;
                width: inherit;
              }
            }
          }
          &__name {
            color: #ffffff;
            font-size: 30px;
            font-weight: bold;
          }
          &__designation {
            font-size: 16px;
            color: #e8f2fe;
          }
          &__social {
            height: 32px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            span {
              background: rgba(#000000, 0.25);
              color: #ffffff;
              cursor: pointer;
              width: 28px;
              height: 28px;
              padding: 3px;
              margin: 0 5px;
              border-radius: 50%;
              transition: all 300ms ease-in;
              text-align: center;

              &:hover {
                background: rgba(#000000, 0.5);
                i {
                  opacity: 1;
                }
              }
              i {
                vertical-align: middle;
                opacity: 0.7;
              }
            }
          }
          &__socialinput {
            .link-input {
              background: #fff;
              .btn-group {
                i {
                  color: #1968fc;
                  border-right: 1px solid #ececec;
                }
              }
              input {
                min-width: 100%;
                background: transparent;
                border: 0;
                font-size: 14px;
                color: #1968fc;
                &:focus {
                  outline: 0;
                }
              }
            }
            .link-action {
              float: right;
              button {
                border: 0;
                i {
                  font-size: 14px;
                  &.confirm {
                    color: #00cfb2;
                  }
                  &.cancel {
                    color: #f65f4d;
                  }
                }
              }
            }
          }
          &__live {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            color: rgba(#ffffff, 0.5);
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      &__right {
        background: #ffffff;
        position: relative;
        height: inherit;
        width: inherit;
        .profile--moreinfo {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          height: inherit;
          width: inherit;
          .info-flex {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 15px 0 15px 50px;
            div {
              width: 50%;
              h5 {
                color: #7f7f7f;
                font-size: 14px;
              }
              p {
                color: #000000;
                font-size: 16px;
              }
            }
          }
        }
        .profile--action {
          position: absolute;
          bottom: 21px;
          right: 0;
          &__btn {
            font-weight: 600;
            border-radius: 4px;
            padding: 5px 18px;
            min-width: 100px;
            text-align: center;
            &:hover {
              cursor: pointer;
            }
            &.primary {
              color: #1968fc;
              background: rgba(#197df5, 0.1);
            }
            &.secondary {
              color: #1968fc;
            }
            &.submit {
              color: #ffffff;
              background: #1968fc;
            }
          }
        }
      }
    }
    select {
      min-width: 200px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      background-color: #ffffff;
      &.apply-placeholder {
        color: #7f7f7f;
      }
      &::-ms-expand {
        display: none;
      }
    }
    &--input {
      border: 0;
      font-size: 16px;
      &::placeholder {
        background: rgba(#1968fc, 0.1);
        padding-left: 5px;
        opacity: 0.5;
      }
      &.name-input {
        background: transparent;
        color: #ffffff;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        &::placeholder {
          background: transparent;
          padding-left: auto;
          color: #ffffff;
          opacity: 0.5;
        }
      }
      &.job-input {
        background: transparent;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        &::placeholder {
          background: transparent;
          padding-left: auto;
          color: #ffffff;
          opacity: 0.5;
        }
      }
      &.c_code {
        width: 40px;
      }
      &:disabled {
        cursor: not-allowed;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

#profile-modal {
  ~ .modal-backdrop {
    background: #ffffff;
    opacity: 0.85;
  }
}

@media (min-width: 992px) {
  #profile-modal .modal-body {
    // padding: 0 6rem;
  }
  #profile-modal .modal-lg,
  #profile-modal .modal-xl {
    max-width: 1024px;
  }
}
@media (max-width: 700px) {
  #profile-modal .modal-body {
    padding: 0;
  }
}
@media (max-width: 700px) {
  .cy-profile .profile--info__right .profile--moreinfo .info-flex {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0 20px;
    div {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
.disableInput {
  pointer-events: none;
  opacity: 0.5;
}
.notAllowed {
  cursor: not-allowed !important;
}
</style>
