var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('cy-head'), _vm._v(" "), _c('cy-nav', {
    attrs: {
      "is-banner-open": _vm.showTopBanner
    },
    on: {
      "subscribeClick": function ($event) {
        return _vm.onClickSubscribe();
      },
      "showBackdrop": _vm.checkBackdrop
    }
  }), _vm._v(" "), _c('main', {
    staticClass: "cy-container",
    class: {
      'cy-bg-overlay': _vm.showBackdrop,
      'cy-container--padding-top': _vm.showTopBanner && _vm.$route.path === '/'
    },
    attrs: {
      "role": "main"
    }
  }, [_c('section', {
    staticClass: "dashboard"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "dashboard--left",
    attrs: {
      "md": "3"
    }
  }, [_c('cy-dashboard-sidebar')], 1), _vm._v(" "), _c('b-col', {
    staticClass: "dashboard--right",
    attrs: {
      "md": "9"
    }
  }, [_c('nuxt')], 1)], 1)], 1)], 1), _vm._v(" "), _c('cy-profile'), _vm._v(" "), _c('cy-footer', {
    class: _vm.showBackdrop ? 'cy-bg-overlay__fade bg-white' : ''
  }), _vm._v(" "), _c('cy-subscriber-modal', {
    attrs: {
      "from-footer": _vm.fromFooter
    },
    on: {
      "fromFooter": function ($event) {
        _vm.fromFooter = false;
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }