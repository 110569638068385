import { format, formatRelative } from 'date-fns'
import vClickOutside from 'v-click-outside'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import Clipboard from 'v-clipboard'
import menuRoutes from '@/config/routes.js'

Vue.prototype.$deviceId = ''
Vue.component('CtaButton', () => import('@/components/miscellaneous/CtaButton'))
Vue.component('CyResourcesCard', () =>
  import('@/components/blog/ResourcesCard')
)
Vue.component('CyPartnerBanner', () =>
  import('@/components/miscellaneous/CyPartnerBanner')
)
Vue.component('CyInputMap', () =>
  import('@/components/miscellaneous/CyInputMap')
)
Vue.component('CyMoreProducts', () =>
  import('@/components/products/CyMoreProducts')
)
Vue.component('CyToolIntegrationCard', () =>
  import('@/components/miscellaneous/CyToolIntegrationCard')
)

Vue.use(vClickOutside)
Vue.use(Clipboard)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.filter('fromtimestamp', function (value) {
  if (value && value.length > 0) {
    const dateArray = value.split('T')[0].split('-')
    return format(
      new Date(dateArray[0], dateArray[1] - 1, dateArray[2]),
      'MMMM dd, yyyy'
    )
  } else {
    return 'NA'
  }
})

Vue.filter('keyToLabel', function (value) {
  return value ? value.replace(/[_-]+/g, ' ') : value
})

Vue.filter('striptags', function (value) {
  if (value) return value.replace(/<\/?[^>]+(>|$)/g, ' ')
  else return value
})

Vue.filter('formattedDate', function (value, type) {
  if (type === 'partial') {
    return format(value, 'MMM dd, yyyy')
  }
  return format(value, 'MMMM dd, yyyy')
})

Vue.filter('epochToDate', function (value, type) {
  const t = new Date(value * 1000)
  return format(t, 'MMM dd, yyyy')
})

Vue.filter('dateToRelative', function (value) {
  const t = new Date(value * 1000)
  const current = new Date()
  return formatRelative(t, current)
})

Vue.mixin({
  computed: {
    isAuthenticated() {
      return !!this.$cookies.get('auth._token.local') && this.userDetails
    },
    userDetails() {
      return this.$store.state.list.USER_PROFILE
    },
    bannerData() {
      return this.$store.state.list.BANNER_DATA
    }
    // getHyperLink() {
    //   return getHyperLink
    // }
  },

  methods: {
    truncate(text) {
      if (text) {
        return this.$options.filters.striptags(text)
      } else {
        return ''
      }
    },
    makeToast(
      variant = null,
      message,
      title,
      toaster = 'b-toaster-top-center'
    ) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: false,
        autoHideDelay: 2000,
        toaster,
        noCloseButton: true
      })
    },
    getHyperLink(slug, query) {
      let queryParams = ''
      if (query) {
        queryParams = '/?' + new URLSearchParams(query).toString()
      }
      const menus = menuRoutes()
      const currentSlugIndex = menus.findIndex((item) => item.slug === slug)
      if (currentSlugIndex !== -1) {
        const currentLink = menus[currentSlugIndex].link + queryParams
        return currentLink
      }
      return '/'
    },
    isEven(num) {
      return num % 2 === 0
    },
    isLastIndex(index, length) {
      return index === length - 1
    }
  }
})
