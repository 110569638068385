<template>
  <div class="d-flex">
    <input
      :value="value.country_code"
      class="profile--input c_code"
      placeholder="+"
      @input="
        (event) =>
          $emit('input', { ...value, country_code: event.target.value })
      "
    />
    <span class="pr-2">/</span>
    <input
      :value="value.ph_number"
      type="number"
      class="profile--input ph_number"
      placeholder="Your Contact Number"
      @input="
        (event) => $emit('input', { ...value, ph_number: event.target.value })
      "
    />
  </div>
</template>
<script>
export default {
  name: 'ContactNumber',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
