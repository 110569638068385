import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9422d866 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _03988fe4 = () => interopDefault(import('../views/quarterback/quarterback' /* webpackChunkName: "" */))
const _9512c540 = () => interopDefault(import('../views/products/solutions/SolutionTemplate' /* webpackChunkName: "" */))
const _4a4f22a9 = () => interopDefault(import('../views/products/ProductTemplate.vue' /* webpackChunkName: "" */))
const _9797f1a4 = () => interopDefault(import('../views/products/IsacIsoCerts' /* webpackChunkName: "" */))
const _57c38a0b = () => interopDefault(import('../views/products/IsacIsoCertsMembers' /* webpackChunkName: "" */))
const _48b7f914 = () => interopDefault(import('../views/products/CtixSpoke' /* webpackChunkName: "" */))
const _68d195fc = () => interopDefault(import('../views/products/CywareBrowserExtension' /* webpackChunkName: "" */))
const _5c3d753b = () => interopDefault(import('../views/support/ChannelPartners' /* webpackChunkName: "" */))
const _43eef3d4 = () => interopDefault(import('../views/support/MSSP' /* webpackChunkName: "" */))
const _52b3220a = () => interopDefault(import('../views/support/ToolIntegrations' /* webpackChunkName: "" */))
const _cf340cc8 = () => interopDefault(import('../views/community/OpenApis' /* webpackChunkName: "" */))
const _77cbaaa8 = () => interopDefault(import('../views/products/suite/Misp' /* webpackChunkName: "" */))
const _5af64a50 = () => interopDefault(import('../views/resources/ResourceLibrary' /* webpackChunkName: "" */))
const _1127df0c = () => interopDefault(import('../views/resources/UseCaseLandingPage' /* webpackChunkName: "" */))
const _3ecaa658 = () => interopDefault(import('../views/resources/ThreatBriefings' /* webpackChunkName: "" */))
const _be0e8698 = () => interopDefault(import('../views/blog/EducationalGuideLanding' /* webpackChunkName: "" */))
const _e0375c48 = () => interopDefault(import('../views/community/ctixfeeds' /* webpackChunkName: "" */))
const _74bfbed2 = () => interopDefault(import('../views/blog/Videos' /* webpackChunkName: "" */))
const _4423fab9 = () => interopDefault(import('../views/resources/CywareCommunity' /* webpackChunkName: "" */))
const _d4354488 = () => interopDefault(import('../views/resources/CywareAcademy' /* webpackChunkName: "" */))
const _62151950 = () => interopDefault(import('../views/company/leadership/LeaderBoard' /* webpackChunkName: "" */))
const _2294c6f0 = () => interopDefault(import('../views/company/careers' /* webpackChunkName: "" */))
const _ab1fc534 = () => interopDefault(import('../views/company/PressMedia' /* webpackChunkName: "" */))
const _506705ee = () => interopDefault(import('../views/company/PressRelease' /* webpackChunkName: "" */))
const _8642f262 = () => interopDefault(import('../views/company/CompliancePage' /* webpackChunkName: "" */))
const _76e0338e = () => interopDefault(import('../views/company/contactus' /* webpackChunkName: "" */))
const _15abecc8 = () => interopDefault(import('../views/blog/Blog' /* webpackChunkName: "" */))
const _c729eb60 = () => interopDefault(import('../views/community/TipReplacement' /* webpackChunkName: "" */))
const _a66200e8 = () => interopDefault(import('../views/community/SoarReplacement' /* webpackChunkName: "" */))
const _67537857 = () => interopDefault(import('../views/company/DemoPage' /* webpackChunkName: "" */))
const _43caf3ae = () => interopDefault(import('../views/support/tech' /* webpackChunkName: "" */))
const _52f7278c = () => interopDefault(import('../views/company/LegalNotice' /* webpackChunkName: "" */))
const _74a604f9 = () => interopDefault(import('../views/products/UseCaseTemplate' /* webpackChunkName: "" */))
const _317dfd04 = () => interopDefault(import('../views/products/IsacMemberSharing' /* webpackChunkName: "" */))
const _3c17ba60 = () => interopDefault(import('../views/company/Sitemap' /* webpackChunkName: "" */))
const _e15d342c = () => interopDefault(import('../views/company/Eula' /* webpackChunkName: "" */))
const _4125931e = () => interopDefault(import('../views/products/CompareCtixProducts' /* webpackChunkName: "" */))
const _17d9033f = () => interopDefault(import('../views/products/CtixLiteMisp' /* webpackChunkName: "" */))
const _5db8f267 = () => interopDefault(import('../views/resources/NtscCisoPolicy' /* webpackChunkName: "" */))
const _d25c8258 = () => interopDefault(import('../views/blog/SingleArticle' /* webpackChunkName: "" */))
const _674b58ed = () => interopDefault(import('../views/blog/EducationalGuidePillar' /* webpackChunkName: "" */))
const _786d4f93 = () => interopDefault(import('../views/blog/EducationalGuideArticle' /* webpackChunkName: "" */))
const _448eafe0 = () => interopDefault(import('../views/blog/TagsResult' /* webpackChunkName: "" */))
const _c8e90842 = () => interopDefault(import('../views/blog/SearchResult' /* webpackChunkName: "" */))
const _1fa884d5 = () => interopDefault(import('../views/blog/Insights' /* webpackChunkName: "" */))
const _13ee4d92 = () => interopDefault(import('../views/dashboard/products' /* webpackChunkName: "" */))
const _d14288f0 = () => interopDefault(import('../views/dashboard/subscriptions' /* webpackChunkName: "" */))
const _4e775932 = () => interopDefault(import('../views/dashboard/ThreatIntelFeeds' /* webpackChunkName: "" */))
const _73557e17 = () => interopDefault(import('../views/dashboard/RecentlyViewedBlogs' /* webpackChunkName: "" */))
const _6f9ce21a = () => interopDefault(import('../views/company/ThankYou' /* webpackChunkName: "" */))
const _8a065616 = () => interopDefault(import('../views/resources/ResearchLab' /* webpackChunkName: "" */))
const _7a14c76c = () => interopDefault(import('../views/support/TechnologyPartners' /* webpackChunkName: "" */))
const _27821153 = () => interopDefault(import('../views/support/environments' /* webpackChunkName: "" */))
const _1ba9ad1c = () => interopDefault(import('../views/blog/InsightList' /* webpackChunkName: "" */))
const _7af14091 = () => interopDefault(import('../views/blog/ResearchAnalysis' /* webpackChunkName: "" */))
const _9d04f248 = () => interopDefault(import('../views/blog/ThreatBriefing' /* webpackChunkName: "" */))
const _09f4e604 = () => interopDefault(import('../views/products/CywareFusionCenter' /* webpackChunkName: "" */))
const _26bccfa4 = () => interopDefault(import('../views/company/VulnerabilityDisclosure' /* webpackChunkName: "" */))
const _546a8590 = () => interopDefault(import('../views/products/usecase/UseCaseLanding' /* webpackChunkName: "" */))
const _4a7df021 = () => interopDefault(import('../views/products/usecase/UseCaseTemplate' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _9422d866,
    name: "index"
  }, {
    path: "/cyware-ai-quarterback",
    component: _03988fe4,
    meta: {"title":"Quarterback","description":undefined,"metaTitle":"AI-Driven Security Operations","metaDescription":"Artificial intelligence is revolutionizing the way we do everything, including cybersecurity. Learn more about Cyware’s AI-Driven SOC solutions.","keywords":undefined,"slug":"quarterback","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware-ai-quarterback"
  }, {
    path: "/products/cyber-fusion-center-solutions",
    component: _9512c540,
    meta: {"title":"Cyber Fusion Center","description":undefined,"metaTitle":"Cyber Fusion Center (CFC) Solutions","metaDescription":"Cyware's Cyber Fusion Center helps security teams to integrate traditionally siloed functions, automate and orchestrate threat operations with high-fidelity threat intel integration, and conduct accurate threat analysis.","keywords":undefined,"slug":"cfc-solutions","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/cyber-fusion-center-solutions"
  }, {
    path: "/threat-intelligence-platform",
    component: _9512c540,
    meta: {"title":"Threat Intelligence Platforms (TIP)","description":undefined,"metaTitle":"Automated Complete Threat Intelligence Platform","metaDescription":"Accelerate threat analysis and investigation while automating the complete threat lintel lifecycle with the Cyware Threat Intelligence Platform.","keywords":"threat intelligence platform","slug":"threat-intelligence-platform","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/threat-intelligence-platform"
  }, {
    path: "/products/threat-intelligence-exchange",
    component: _4a4f22a9,
    meta: {"title":"Cyware Threat Intelligence eXchange (CTIX)","description":undefined,"metaTitle":"Threat Intelligence Exchange (CTIX)","metaDescription":"Cyware's Threat Intelligence Exchange (CTIX) enables the ingestion, enrichment, analysis, prioritization, and actioning of threat data. It also facilitates the bidirectional sharing of threat information within your trusted network. Request a demo today to see how CTIX can benefit your organization.","keywords":"STIX \u002F TAXII, stix taxii, taxii, taxii server, stix and taxii, Threat intelligence platform, Threat intelligence automation, Threat intelligence sharing, Threat intelligence analysis, Threat intelligence lifecycle, Threat intelligence enrichment, Tactical threat intelligence, threat intel feeds, TAXII Server, Threat Intel Platform, threat intelligence tools ","slug":"ctix","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/threat-intelligence-exchange"
  }, {
    path: "/products/threat-intelligence-lite",
    component: _4a4f22a9,
    meta: {"title":"Cyware Threat Intelligence eXchange (CTIX)","description":undefined,"metaTitle":"Intel Exchange Lite | Threat Intelligence Exchange","metaDescription":"Cyware’s Intel Exchange Lite enables heightened threat intelligence exchange capabilities for small and medium-sized security teams.","keywords":"STIX \u002F TAXII, stix taxii, taxii, taxii server, stix and taxii, Threat intelligence platform, Threat intelligence automation, Threat intelligence sharing, Threat intelligence analysis, Threat intelligence lifecycle, Threat intelligence enrichment, Tactical threat intelligence, threat intel feeds, TAXII Server, Threat Intel Platform, threat intelligence tools ","slug":"ctix-lite","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/threat-intelligence-lite"
  }, {
    path: "/products/cybersecurity-collaboration-platform",
    component: _4a4f22a9,
    meta: {"title":"Cyware Situational Awareness Platform (CSAP)","description":undefined,"metaTitle":"Cybersecurity Collaboration | ISAC Information Sharing","metaDescription":"With the ISAC Information Sharing Platform from Cyware, you can aggregate, enrich, and share threat information efficiently in real-time.","keywords":"Threat intelligence, Cyber situational awareness, Security situational awareness, Strategic threat intelligence, Operational threat intelligence, isac information sharing","slug":"csap","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/cybersecurity-collaboration-platform"
  }, {
    path: "/products/incident-analysis-threat-response-platform",
    component: _4a4f22a9,
    meta: {"title":"Cyware Fusion and Threat Response (CFTR)","description":undefined,"metaTitle":"Highly Advanced Automated Incident Response","metaDescription":"When under cyberattack, a quick response is mission critical. With Cyware's industry-best automated incident response, your data is secure.","keywords":"Threat management, Security case management, Security orchestration automation and response, SOAR platform, Incident response automation, Incident response platform, Incident management, Incident management platform, Threat intelligence fusion, Cyber fusion platform, automated incident response","slug":"cftr","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/incident-analysis-threat-response-platform"
  }, {
    path: "/products/orchestration-automation-platform",
    component: _4a4f22a9,
    meta: {"title":"Cyware Orchestrate","description":undefined,"metaTitle":"User-Friendly Data Security Automation from Cyware","metaDescription":"With Cyware's Security Automation, you can automate security workflows across cloud and on-premise environments in one integrated marketplace.","keywords":"incident response orchestration, security incident management tools, security orchestration and automation response, security orchestration and response, security orchestration automated response, soar cyber security, soar cyber security tools, soar security tools, soar tools gartner, soar tools security, security automation","slug":"cyware-orchestrate","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/orchestration-automation-platform"
  }, {
    path: "/products/isacs-isaos-certs",
    component: _9797f1a4,
    meta: {"title":"Solutions for ISACs, ISAOs, and CERTs","description":"An Any-to-Any Vendor Agnostic Orchestration platform for connecting & automating Cyber, IT, and DevOps workflows across Cloud, On-Premise, and Hybrid environments.","metaTitle":"ISAC [ISAO] Platform | CERT Platform","metaDescription":"Cyware enables threat intelligence sharing across the majority of ISACs, ISAOs, and CERTs to help their members anticipate, prevent, and respond to threats. With a platform designed for threat sharing communities, Cyware empowers organizations to enhance their threat intelligence capabilities.","keywords":undefined,"slug":"isac-iso-certs","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/isacs-isaos-certs"
  }, {
    path: "/products/isac-isao-cert-members",
    component: _57c38a0b,
    meta: {"title":"Solutions for ISAC, ISAO, and CERT Members","description":"An Any-to-Any Vendor Agnostic Orchestration platform for connecting & automating Cyber, IT, and DevOps workflows across Cloud, On-Premise, and Hybrid environments.","metaTitle":"ISAC Threat Intelligence | Cyware Collaboration","metaDescription":"As a member of a major ISAC Platform, you can anticipate, prevent, and respond to threats through bi-directional threat intelligence sharing and automation.","keywords":undefined,"slug":"isac-iso-certs-members","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/isac-isao-cert-members"
  }, {
    path: "/products/intel-exchange-spoke",
    component: _48b7f914,
    meta: {"title":"Intel Exchange Spoke","description":undefined,"metaTitle":"Intelligence Exchange Spoke","metaDescription":"Cyware's Intel Exchange Spoke offers a trusted environment for ISAC\u002FISAO members to process and collaborate on threat intelligence, enabling operationalization of threat intelligence.","keywords":"ctix spoke, threat intelligence platform, threat intel feeds, cyber threat intelligence feeds, threat intelligence platform vendors, cyber threat intelligence platform","slug":"ctix-spoke","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/intel-exchange-spoke"
  }, {
    path: "/products/crawler-browser-extension",
    component: _68d195fc,
    meta: {"title":"Cyware Threat Intel Crawler","description":undefined,"metaTitle":"Threat Intel Crawler","metaDescription":"Cyware Threat Intel Crawler is a browser extension that utilizes Machine Learning (ML) and Natural Language Processing (NLP) to identify and operationalize threat intelligence from web-based content. It detects and crawls relevant information to help organizations stay informed and secure.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"cyware-browser-extension","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/crawler-browser-extension"
  }, {
    path: "/partners/channel-partners",
    component: _5c3d753b,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Partner With Us | Cyware Channel Partner Program","metaDescription":"Incorporate cutting-edge cyber threat intelligence solutions into your selling profile & see the value Cyware products can bring to your Channel Partner Program.","keywords":undefined,"slug":"channelpartners","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/channel-partners"
  }, {
    path: "/partners/mssp",
    component: _43eef3d4,
    meta: {"title":"MSSPs","description":"Automate security monitoring and response for your clients.","metaTitle":"MSSP Automation | Managed Security Service Providers","metaDescription":"MSSP Automation - Cyware’s solutions facilitate scalable and integrated management of all your client security operations. This advanced solution ensures that real-time notification and alerting on security threats. Get a Demo!","keywords":"Managed Security Service Providers, cyber fusion solutions, threat intelligence, cyber security solutions, mssp automation          ","slug":"mssp","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/mssp"
  }, {
    path: "/partners/technology-alliances",
    component: _52b3220a,
    meta: {"title":"Technology Alliances","description":undefined,"metaTitle":"Technology Alliances","metaDescription":"Cybersecurity automation & tool integratoin is a security process. Enhance your cyber security services with powerful synergies made possible through Cyware’s wide-ranging tool integration solutions.","keywords":undefined,"slug":"tool-integrations","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/technology-alliances"
  }, {
    path: "/partners/open-apis",
    component: _cf340cc8,
    meta: {"title":"Open APIs","description":undefined,"metaTitle":" Open APIs Overview","metaDescription":"Cyware's Open Application programming interfaces ( Open APIs) products make everything better & easier - from data sharing to system connectivity to delivery of critical features & functionality. Get a Demo from Cyware for Better & Safe IT environment.","keywords":undefined,"slug":"openapi","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/open-apis"
  }, {
    path: "/partners/misp",
    component: _77cbaaa8,
    meta: {"title":undefined,"description":undefined,"metaTitle":"MISP Threat Sharing Platform | Cyware","metaDescription":"Integrating MISP with Cyware Threat Intelligence Exchange enhances your organization’s threat intelligence sharing capabilities. Request a demo today.","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"misp","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/misp"
  }, {
    path: "/resources/resource-library",
    component: _5af64a50,
    meta: {"title":"Resource Library","description":undefined,"metaTitle":"Resource Library | Download Industry Briefs, Industry Reports, White Papers, Product Datasheets and Use Cases","metaDescription":"Cyware Resource Library Downloads: Learn how you can stay ahead of threats with cyber fusion, threat intelligence automation, and response solutions by downloading our Industry Briefs, Industry Reports, White Papers, Datasheets and Use Cases.","keywords":"Download Industry Briefs, Industry Reports, White Papers, CSAP Datasheets, CTIX datasheet, CFTR datasheet, Cyware Orchestrate datasheet, CTIX lite datasheet, product use cases.","slug":"resource-library","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/resource-library"
  }, {
    path: "/cyware/use-cases",
    component: _1127df0c,
    meta: {"title":"Use Cases","description":undefined,"metaTitle":"Cybersecurity Use Cases","metaDescription":"Explore how Cyware's threat intelligence and security automation solutions enhance your organization's cybersecurity through our use cases.","keywords":"Cybersecurity Use Cases","slug":"usecase-landing-page","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-cases"
  }, {
    path: "/resources/threat-briefings",
    component: _3ecaa658,
    meta: {"title":"Cyware Labs: Research & Threat Briefings","description":undefined,"metaTitle":"Cyware Labs: Research & Threat Briefings","metaDescription":"Cyware Labs: Research & Threat Briefings","keywords":"cyber security resources, cybersecurity community, cyber security learning resources, free cyber security tools, free cybersecurity resources, best cybersecurity resources, cyber security free tools, cyber security training tools","slug":"threat-briefings","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings"
  }, {
    path: "/resources/security-guides",
    component: _be0e8698,
    meta: {"title":"Security Guides","description":"Boost your security knowledge with Cyware's Educational Blogs that cover a variety of topics ranging from new ideas, latest security solutions and innovative strategies","metaTitle":"Cyware Security Guides | Cyber Security & Threat Intelligence Updates","metaDescription":"Boost your security knowledge with Cyware's Educational Blogs that cover a variety of topics ranging from new ideas, latest security solutions and innovative strategies","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"educational-guides","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/security-guides"
  }, {
    path: "/resources/threat-intel-feeds",
    component: _e0375c48,
    meta: {"title":"Free Threat Intel Feeds","description":"Cyber Threat Intelligence Feeds - Cyware’s threat intel feed provides users with constantly updated information about potential sources of cyber-attack. Keep track of emerging threats that could pose risks to your organization at any time and from anywhere.","metaTitle":"Threat Intelligence Feeds| Free Cyware Threat Intelligence Feeds","metaDescription":"Cyber Threat Intelligence Feeds – Stay ahead of the curve and keep track of emerging threats that could pose risk to your organization at any time and from anywhere. Get Access Now!","keywords":"threat intelligence feeds","slug":"ctix-feeds","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/threat-intel-feeds"
  }, {
    path: "/resources/webinar-and-videos",
    component: _74bfbed2,
    meta: {"title":"Webinars & Videos","description":undefined,"metaTitle":" Cyber Threat Intelligence & Automation Videos","metaDescription":"Watch Cyware's Cyber Threat Intelligence & Automation Videos to learn more about threat intelligence automation, threat response, security orchestration, cyber fusion, and more.","keywords":"View our library of videos to learn more about threat intelligence automation, threat response, security orchestration, cyber fusion, and more.","slug":"videos","headerImg":"\u002Fblog\u002Fresearch-analysis.svg","showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/webinar-and-videos"
  }, {
    path: "/resources/cyware-community",
    component: _4423fab9,
    meta: {"title":"Community Resources","description":undefined,"metaTitle":"Cyware Community Resources: Cyber Security Free Tools, Learning Resources","metaDescription":"Explore Cyber security community resources to get free Cyware Threat Intelligence Feeds access and exclusive update on Cybersecurity news, articles, tools, and other free resources.","keywords":"cyber security resources, cybersecurity community, cyber security learning resources, free cyber security tools, free cybersecurity resources, best cybersecurity resources, cyber security free tools, cyber security training tools","slug":"cyber-community","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/cyware-community"
  }, {
    path: "/resources/cyware-academy",
    component: _d4354488,
    meta: {"title":"Cyware Academy","description":undefined,"metaTitle":"Cyware Academy","metaDescription":"Cyware Academy","keywords":"View our library of videos to learn more about threat intelligence automation, threat response, security orchestration, cyber fusion, and more.","slug":"cyber-academy","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/cyware-academy"
  }, {
    path: "/company/leadership",
    component: _62151950,
    meta: {"title":"Leadership","description":"Meet the executive team that is driving innovation at Cyware.","metaTitle":"Leadership & Business Advisors | Cyware Labs","metaDescription":"Cyware Labs leadership is made up of founders, Anuj Goel and Akshat Jain, in addition to various business advisors. Read more on our website about the team.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"leadership","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/leadership"
  }, {
    path: "/company/careers",
    component: _2294c6f0,
    meta: {"title":"Careers","description":"Join Cyware and be a part of the team reimagining tomorrow’s cybersecurity.","metaTitle":"Join Our Team | Careers & Jobs in Cyber Security","metaDescription":"Join Cyware and be part of the team reimagining tomorrow’s cybersecurity. Start your career in cyber security. Apply now!","keywords":undefined,"slug":"careers","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/careers"
  }, {
    path: "/company/press-media",
    component: _ab1fc534,
    meta: {"title":"Cyware in the News","description":"Catch the latest Cyware headlines in the press and media.","metaTitle":" Press Releases, News, Articles, Podcasts, & Interviews","metaDescription":"Check out Cyware's News, Articles, Podcasts & Interviews about cyber security, malware attack updates and more at Cyware.com. Our machine learning based curation engine brings you the top and relevant cyber security content. Read More!","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"pressmedia","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/press-media"
  }, {
    path: "/company/press-releases",
    component: _506705ee,
    meta: {"title":"Press Releases","description":"Catch the latest Cyware headlines in the press and media.","metaTitle":" Press Releases – Threat Intelligence Solution Company","metaDescription":"Discover the latest news about Cyware's cyber security, Top Breach, Malware attacks, Vulnerabilities & Threat Intelligence about new threat, complex attacks.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"pressrelease","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/press-releases"
  }, {
    path: "/company/compliance",
    component: _8642f262,
    meta: {"title":"Compliance","description":undefined,"metaTitle":"Cyware Compliance and Certifications","metaDescription":"Cyware's FedRAMP Ready, ISO 27001, SOC 2 Type 2, and VPAT compliances and certifications ensure robust data protection and commitment to security.","keywords":undefined,"slug":"compliancepage","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/compliance"
  }, {
    path: "/company/contact-us",
    component: _76e0338e,
    meta: {"title":"Contact Us","description":undefined,"metaTitle":"Contact Us Today | Get in Touch With Cyware","metaDescription":"Identify and prioritize threats with Cyware's real-time security updates. Contact us today for more information on our products.","keywords":undefined,"slug":"contactus","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/company/contact-us"
  }, {
    path: "/cyware-ai-quarterback",
    component: _03988fe4,
    meta: {"title":"Cyware AI","description":undefined,"metaTitle":"Cyware AI","metaDescription":"Cyware AI","keywords":undefined,"slug":"quarterback","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware-ai-quarterback"
  }, {
    path: "/blog",
    component: _15abecc8,
    meta: {"title":"Blog","description":"Learn how our products and solutions are solving the most difficult and complex challenges of the security threat landscape.","metaTitle":"Cyware Company Blog | Cyber Security & Threat Intelligence Updates","metaDescription":"Learn how our products and solutions are solving the most difficult and complex challenges of the security threat landscape.","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"blog","headerImg":"\u002Fblog\u002Fblog-hero.svg","showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/blog"
  }, {
    path: "/threat-intelligence-platform-comparison",
    component: _c729eb60,
    meta: {"title":"TIP Repalacement","description":undefined,"metaTitle":"Threat Intelligence Platform Comparison","metaDescription":"When searching for a reliable threat intelligence platform comparison, consider replacing your legacy TIP with Cyware.","keywords":"threat intelligence platform comparison","slug":"tipreplacement","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/threat-intelligence-platform-comparison"
  }, {
    path: "/compare-soar-platform",
    component: _a66200e8,
    meta: {"title":"SOAR Replacement","description":undefined,"metaTitle":"Compare SOAR Platforms","metaDescription":"Looking to replace your legacy SOAR platform? See how Cyware compares to other SOAR platforms and rethink your cybersecurity.","keywords":"compare soar platform","slug":"soarreplacement","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/compare-soar-platform"
  }, {
    path: "/request-a-demo",
    component: _67537857,
    meta: {"title":"Request a Demo","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Request a Demo of Cyware's Solutions | TIP | SOAR | Cyber Fusion","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"demo-page","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/request-a-demo"
  }, {
    path: "/tech-support-plan",
    component: _43caf3ae,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Threat Intelligence & Cyber Security Technical Support Plans","metaDescription":"Know about Cyware's Technical Support plans offers to fit your needs. Plans include technical support with Service Pack Updates, Bug Fixes, New Application Builds\u002FVersions, Backend Enhancements, Security Patches.","keywords":undefined,"slug":"support-tech","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/tech-support-plan"
  }, {
    path: "/legal/end-user-terms",
    component: _52f7278c,
    meta: {"title":"Legal","description":undefined,"metaTitle":"Cyware Legal","metaDescription":"Cyware Legal","keywords":undefined,"slug":"end-user-terms","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/legal/end-user-terms"
  }, {
    path: "/cyware/use-case/automated-phishing-email-analysis-and-response",
    component: _74a604f9,
    meta: {"title":"Automated Phishing Email Analysis and Response","description":undefined,"metaTitle":"Automated Phishing Analysis | Email Analysis Tool","metaDescription":"Discover how Cyware’s automated phishing analysis tool can detect and neutralize email phishing threats efficiently. Learn more today!","keywords":"phishing email analysis","slug":"automated-fishing-email","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/automated-phishing-email-analysis-and-response"
  }, {
    path: "/cyware/use-case/detect-analyze-and-act-on-edr-identified-malicious-processes",
    component: _74a604f9,
    meta: {"title":"Detect, Analyze, and Action on Malicious Process Detection by EDR","description":undefined,"metaTitle":"Malicious Process Detection by EDR","metaDescription":"Explore how EDR can aid in malicious process detection, analysis, and removal. Strengthen your cybersecurity measures today.","keywords":"malicious process detection","slug":"malicious-processes","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/detect-analyze-and-act-on-edr-identified-malicious-processes"
  }, {
    path: "/cyware/use-case/credential-compromise-attempt-detection-and-automated-response",
    component: _74a604f9,
    meta: {"title":"Credential Compromise Attempt Detection & Automated Response","description":undefined,"metaTitle":"Credential Compromise Detection & Response","metaDescription":"Safeguard your organization with credential compromise detection and automated response solutions. Effectively detect and mitigate credential threats today.","keywords":"credential compromise","slug":"attempt-detection-automated-response","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/credential-compromise-attempt-detection-and-automated-response"
  }, {
    path: "/cyware/use-case/ransomware-detection-and-response",
    component: _74a604f9,
    meta: {"title":"Ransomware Detection & Response","description":undefined,"metaTitle":"Ransomware Detection & Response Solutions","metaDescription":"Learn how to effectively detect, prevent, and respond to ransomware attacks. Safeguard your business with Cyware’s ransomware detection solutions.","keywords":"ransomware detection","slug":"ransomware-detection-response","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/ransomware-detection-and-response"
  }, {
    path: "/cyware/use-case/threat-intel-hunt-and-action",
    component: _74a604f9,
    meta: {"title":"Threat Intel Hunt & Action","description":undefined,"metaTitle":"Threat Hunting & Threat Intelligence Solutions","metaDescription":"Cyware Threat Intel Crawler is a browser extension that utilizes Machine Learning (ML) and Natural Language Processing (NLP) to identify and operationalize threat intelligence from web-based content. It detects and crawls relevant information to help organizations stay informed and secure.","keywords":"Threat intel hunting","slug":"threat-intel-hunting-actioning","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/threat-intel-hunt-and-action"
  }, {
    path: "/cyware/use-case/threat-intel-enrichment-process-automation",
    component: _74a604f9,
    meta: {"title":"Threat Intel Enrichment Process Automation","description":undefined,"metaTitle":"Automated Threat Intelligence Enrichment","metaDescription":"Threat intelligence enrichment is critical for cybersecurity, but it takes time. Automated threat intelligence enrichment can analyze threat data in seconds.","keywords":"automated threat intelligence, cyber security operations, threat intelligence, cyber threat intelligence, cyber security intelligence, cyber threat analysis","slug":"threat-intel-enrichment-process-automation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/threat-intel-enrichment-process-automation"
  }, {
    path: "/cyware/use-case/vulnerability-management",
    component: _74a604f9,
    meta: {"title":"Vulnerability Management","description":undefined,"metaTitle":"Vulnerability Management Process & Solutions","metaDescription":"Protect your organization with Cyware’s vulnerability management solutions. We’ll help you identify, assess, and remediate vulnerabilities effectively.","keywords":"vulnerability management","slug":"vulnerability-management","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware/use-case/vulnerability-management"
  }, {
    path: "/isac-isao-members",
    component: _317dfd04,
    meta: {"title":"ISAC\u002FISAO Members","description":"ISAC\u002FISAO Members","metaTitle":"Threat Intelligence Solutions for ISAC\u002FISAO Members","metaDescription":"Cyware offers personalized threat intelligence solutions for ISAC\u002FISAO members to achieve end-to-end threat intelligence sharing, collective defense and automated workflow. Request a demo!","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"isac-member-sharing","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/isac-isao-members"
  }, {
    path: "/sitemap",
    component: _3c17ba60,
    meta: {"title":"Sitemap","description":undefined,"metaTitle":"Sitemap","metaDescription":"Sitemap","keywords":"View our library of videos to learn more about threat intelligence automation, threat response, security orchestration, cyber fusion, and more.","slug":"sitemap","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/sitemap"
  }, {
    path: "/eula",
    component: _e15d342c,
    meta: {"title":"Eula","description":undefined,"metaTitle":"Eula","metaDescription":"Eula","keywords":undefined,"slug":"eula","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":true,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/eula"
  }, {
    path: "/legal/website-terms-of-use",
    component: _52f7278c,
    meta: {"title":"Cyware Website Terms of Use","description":undefined,"metaTitle":"Cyware Website Terms of Use","metaDescription":"Cyware Website Terms of Use","keywords":undefined,"slug":"website-terms-of-use","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/legal/website-terms-of-use"
  }, {
    path: "/legal/privacy-policy",
    component: _52f7278c,
    meta: {"title":"Cyware Privacy Policy","description":undefined,"metaTitle":"Cyware Privacy Policy","metaDescription":"Cyware Privacy Policy","keywords":undefined,"slug":"privacy-policy","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/legal/privacy-policy"
  }, {
    path: "/products/compare-ctix-products",
    component: _4125931e,
    meta: {"title":"Compare CTIX Product Editions","description":"Compare CTIX Product Editions","metaTitle":"Compare CTIX Lite Vs CTIX Enterprise | CTIX Products","metaDescription":"Compare CTIX products editions & know more about it's Dashboard, Live Activities, Intel Collection, Inbox Capability, Intel Scoring and other major features.","keywords":"Compare CTIX Product Editions","slug":"compare-ctix-products","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/compare-ctix-products"
  }, {
    path: "/products/ctixlite-vs-misp",
    component: _17d9033f,
    meta: {"title":"Compare CTIX Lite vs MISP","description":"Compare CTIX Lite vs MISP","metaTitle":"Compare CTIX Lite vs MISP | Threat Intelligence Platform","metaDescription":"Cyware, the leading Threat intelligence and cyber fusion solutions provider, announced the launch of their latest solution - CTIX Lite. Know the Features\u002FCapabilities of CTIX Lite compared to MISP. Know more how your organization can benefit from CTIX Lite? Request Free Demo From Cyware","keywords":"Threat intelligence, CTIX Lite, MISP, Threat Intelligence Platform, best cyber security companies, cyber security products","slug":"ctixlite-vs-misp","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/products/ctixlite-vs-misp"
  }, {
    path: "/ntsc-ciso-policy",
    component: _5db8f267,
    meta: {"title":"NTSC CISO Policy","description":"NTSC CISO Policy","metaTitle":"NTSC CISO Policy","metaDescription":"NTSC CISO Policy","keywords":"NTSC CISO Policy","slug":"ntsc-ciso","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/ntsc-ciso-policy"
  }, {
    path: "/resources/threat-briefings/daily-threat-briefing/:id",
    component: _d25c8258,
    meta: {"title":"Daily Threat Briefing","description":"Daily Threat Briefing","metaTitle":undefined,"metaDescription":undefined,"keywords":undefined,"slug":"daily-threat-briefing","headerImg":"\u002Fblog\u002Fdaily-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/daily-threat-briefing/:id"
  }, {
    path: "/resources/threat-briefings/weekly-threat-briefing/:id",
    component: _d25c8258,
    meta: {"title":"Weekly Threat Briefing","description":"Weekly Threat Briefing","metaTitle":undefined,"metaDescription":undefined,"keywords":undefined,"slug":"weekly-threat-briefing","headerImg":"\u002Fblog\u002Fweekly-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/weekly-threat-briefing/:id"
  }, {
    path: "/resources/threat-briefings/monthly-threat-briefing/:id",
    component: _d25c8258,
    meta: {"title":"Monthly Threat Briefing","description":"Monthly Threat Briefing","metaTitle":undefined,"metaDescription":undefined,"keywords":undefined,"slug":"monthly-threat-briefing","headerImg":"\u002Fblog\u002Fmonthly-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/monthly-threat-briefing/:id"
  }, {
    path: "/resources/research-and-analysis/:id",
    component: _d25c8258,
    meta: {"title":"Research and Analysis","description":undefined,"metaTitle":"Computer Emergency Response Teams | Threat Intelligence Sharing","metaDescription":"Threat Response Sharing - Cywareâ€™s solutions enable Computer Emergency Response Teams (CERTs) to leverage advanced automation to ingest, analyze, and share strategic and technical threat intelligence on advanced threats. Get started now!","keywords":"threat intelligence sharing, computer emergency response team, cyber emergency response team, threat intelligence sharing platform, threat sharing platform, cyber intelligence sharing platform","slug":"research-and-analysis","headerImg":undefined,"showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/resources/research-and-analysis/:id"
  }, {
    path: "/security-guides/:category",
    component: _674b58ed,
    meta: {"title":"Security Guides","description":"Security Guides","metaTitle":"Security Guides","metaDescription":"Security Guides","keywords":undefined,"slug":"educational-pillar","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/security-guides/:category"
  }, {
    path: "/security-guides/:category/:id",
    component: _786d4f93,
    meta: {"title":"Security Guides","description":"Security Guides","metaTitle":"Security Guides","metaDescription":"Security Guides","keywords":undefined,"slug":"educational-article","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/security-guides/:category/:id"
  }, {
    path: "/:type/tag/:tagId",
    component: _448eafe0,
    meta: {"title":"Cyware Blog","description":"Get to know about our products and how they are transforming security landscape through short and succinct blogs.","metaTitle":undefined,"metaDescription":undefined,"keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"tags","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/:type/tag/:tagId"
  }, {
    path: "/enterprise-search",
    component: _c8e90842,
    meta: {"title":"Cyware Search Results","description":"Get to know about our products and how they are transforming security landscape through short and succinct blogs.","metaTitle":undefined,"metaDescription":undefined,"keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"enterprise-search","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/enterprise-search"
  }, {
    path: "/insights/:id",
    component: _1fa884d5,
    meta: {"title":"Cyware Insights","description":"Learn how Cyware's suite of Cyber Fusion solutions is transforming the security industry with innovative technology and advanced use cases.","metaTitle":"Cyware Insights | Cyber Security & Threat Intelligence Updates","metaDescription":"Learn how Cyware's suite of Cyber Fusion solutions is transforming the security industry with innovative technology and advanced use cases.","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"cyware-insights","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/insights/:id"
  }, {
    path: "/dashboard/products",
    component: _13ee4d92,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Cyber Security News Today | Articles on Cyber Security, Malware Attack updates","metaDescription":"Cyber News - Check out top news and articles about cyber security, malware attack updates and more at Cyware.com. Our machine learning based curation engine brings you the top and relevant cyber security content. Read More!","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"cy-products","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":true,"reference":undefined},
    name: "/dashboard/products"
  }, {
    path: "/dashboard/subscriptions",
    component: _d14288f0,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Cyber Security News Today | Articles on Cyber Security, Malware Attack updates","metaDescription":"Cyber News - Check out top news and articles about cyber security, malware attack updates and more at Cyware.com. Our machine learning based curation engine brings you the top and relevant cyber security content. Read More!","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"subscriptions","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":true,"reference":"USER_PROFILE"},
    name: "/dashboard/subscriptions"
  }, {
    path: "/dashboard/threat-intel-feeds",
    component: _4e775932,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Cyber Security News Today | Articles on Cyber Security, Malware Attack updates","metaDescription":"Cyber News - Check out top news and articles about cyber security, malware attack updates and more at Cyware.com. Our machine learning based curation engine brings you the top and relevant cyber security content. Read More!","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"threat-intel-feeds","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":true,"reference":"USER_PROFILE"},
    name: "/dashboard/threat-intel-feeds"
  }, {
    path: "/dashboard/recently-viewed-blogs",
    component: _73557e17,
    meta: {"title":undefined,"description":undefined,"metaTitle":"Recently Viewed Blogs | Articles on Cyber Security, Malware Attack updates","metaDescription":"Cyber News - Check out top news and articles about cyber security, malware attack updates and more at Cyware.com. Our machine learning based curation engine brings you the top and relevant cyber security content. Read More!","keywords":"cyber security news, Cyber News, cyber security news today, cyber security news articles, news cyber security, top cyber security news, articles on cyber security, cyber security updates, cyber updates, cyber security news sites, cyber news today, news on cyber security, cyber security articles","slug":"recently-viewed-blogs","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":true,"reference":"USER_PROFILE"},
    name: "/dashboard/recently-viewed-blogs"
  }, {
    path: "/thankyou-ctixlite",
    component: _6f9ce21a,
    meta: {"title":"Thank you | CTIX Lite","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | CTIX Lite","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-ctixlite","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-ctixlite"
  }, {
    path: "/thankyou-ctixspoke",
    component: _6f9ce21a,
    meta: {"title":"Thank you | CTIX Spoke","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | CTIX Spoke","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-ctixspoke","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-ctixspoke"
  }, {
    path: "/thankyou-demo-requested",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Demo Request Confirmed","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | Demo Request Confirmed","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-demo","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-demo-requested"
  }, {
    path: "/thankyou-contact",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Contact Us","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | Contact Us","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-contact","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-contact"
  }, {
    path: "/thankyou-partner",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Cyware Partner","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | Cyware Partner","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-partner","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-partner"
  }, {
    path: "/thankyou-report-bug",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Report a Bug","description":"Cyware Situational Awareness Platform offers strategic threat intel, incident reporting, & secure communication externally to minimize risk. Request A Demo Now!","metaTitle":"Thank you | Report a Bug","metaDescription":"Request a demo of Cyware's solutions to get a customized walkthrough of our threat intelligence, SOAR, threat response, and cyber fusion solutions.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-reportabug","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-report-bug"
  }, {
    path: "/thankyou-gartner-guide",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Gartner SOAR Market Guide","description":"Gartner SOAR Market Guide","metaTitle":"Thank you | Gartner SOAR Market Guide","metaDescription":"Gartner SOAR Market Guide","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-gartner","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-gartner-guide"
  }, {
    path: "/thankyou-aite-report",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Aite Impact Report","description":"Aite Impact Report","metaTitle":"Thank you | Aite Impact Report","metaDescription":"Aite Impact Report","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-aite","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-aite-report"
  }, {
    path: "/thankyou-cyware-fusion-center",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Cyber Fusion Center","description":"Cyber Fusion Center","metaTitle":"Thank you | Cyber Fusion Center","metaDescription":"Cyber Fusion Center","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-cfc","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-cyware-fusion-center"
  }, {
    path: "/thankyou-Q3-Ransomware-Index-Report",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Q3 Ransomware Index Report","description":"Q3 Ransomware Index Report","metaTitle":"Thank you | Q3 Ransomware Index Report","metaDescription":"Q3 Ransomware Index Report","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-ransomware","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-Q3-Ransomware-Index-Report"
  }, {
    path: "/thankyou-forrester-opportunity-snapshot-form",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Forrester Opportunity  Snapshot Form","description":"Forrester Opportunity Snapshot Form","metaTitle":"Thank you | Forrester Opportunity Snapshot Form","metaDescription":"Forrester Opportunity Snapshot Form","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-forrester","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-forrester-opportunity-snapshot-form"
  }, {
    path: "/thankyou-cyber-fusion-endpoint-security-form",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Cyber Fusion Endpoint Security Form","description":"Cyber Fusion Endpoint Security Form","metaTitle":"Thank you | Cyber Fusion Endpoint Security Form","metaDescription":"Cyber Fusion Endpoint Security Form","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-cfes","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-cyber-fusion-endpoint-security-form"
  }, {
    path: "/thankyou-ransomware-spotlight-report",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Ransomeware Spotlight Report","description":"Ransomeware Spotlight Report","metaTitle":"Thank you | Ransomeware Spotlight Report","metaDescription":"Ransomeware Spotlight Report","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-rsr","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-ransomware-spotlight-report"
  }, {
    path: "/thankyou-security-threat-intelligence-form",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Security Threat Intelligence Form","description":"Market guide for Security Threat Intelligence Form","metaTitle":"Thank you | Security Threat Intelligence Form","metaDescription":"Security Threat Intelligence Form","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-stif","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-security-threat-intelligence-form"
  }, {
    path: "/thankyou-elite80-report",
    component: _6f9ce21a,
    meta: {"title":"Thank you | JMP Securities Elite 80 Report","description":"JMP Securities Elite 80 Report","metaTitle":"Thank you | JMP Securities Elite 80 Report","metaDescription":"JMP Securities Elite 80 Report","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-elite80","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-elite80-report"
  }, {
    path: "/thankyou-cyber-fusion-for-vulnerability-management",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Cyber Fusion for Vulnerability Management","description":"Cyber Fusion for Vulnerability Management","metaTitle":"Thank you | Cyber Fusion for Vulnerability Management","metaDescription":"Cyber Fusion for Vulnerability Management","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-cfvm","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-cyber-fusion-for-vulnerability-management"
  }, {
    path: "/thankyou-ransomware-index-report-for-vulnerability-management",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Ransomware Report: Through the Lens of Threat and Vulnerability Management","description":"Ransomware Report: Through the Lens of Threat and Vulnerability Management","metaTitle":"Thank you | Ransomware Report: Through the Lens of Threat and Vulnerability Management","metaDescription":"Ransomware Report: Through the Lens of Threat and Vulnerability Management","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-rivm","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-ransomware-index-report-for-vulnerability-management"
  }, {
    path: "/thankyou-gartner-2022-soar-report",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Gartner 2022 Soar Report","description":"Gartner 2022 Report for Security Orchestration, Automation and Response Solutions","metaTitle":"Thank you | Gartner 2022 Soar Report","metaDescription":"Gartner 2022 Report for Security Orchestration, Automation and Response Solutions","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-gartnersoar","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-gartner-2022-soar-report"
  }, {
    path: "/thankyou-forrester-now-tech",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Forrester Now Tech : SOAR 2022","description":"Forrester Now Tech : Security Orchestration, Automation, And Response (SOAR), Q2 2022","metaTitle":"Thank you | Forrester Now Tech : SOAR 2022","metaDescription":"Forrester Now Tech : Security Orchestration, Automation, And Response (SOAR), Q2 2022","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-forrestertech","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-forrester-now-tech"
  }, {
    path: "/thankyou-ransomware-index-report-for-vulnerability-management-index-update-Q2-Q3-2022",
    component: _6f9ce21a,
    meta: {"title":"Thank you | Ransomware Report: Through the Lens of Threat and Vulnerability Management","description":"Ransomware Report: Through the Lens of Threat and Vulnerability Management, Index Update Q2-Q3 2022","metaTitle":"Thank you | Ransomware Report: Through the Lens of Threat and Vulnerability Management","metaDescription":"Ransomware Report: Through the Lens of Threat and Vulnerability Management, Index Update Q2-Q3 2022","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"ty-ransomware-report","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/thankyou-ransomware-index-report-for-vulnerability-management-index-update-Q2-Q3-2022"
  }, {
    path: "/resources/research-lab",
    component: _8a065616,
    meta: {"title":undefined,"description":"Forrester Now Tech : Security Orchestration, Automation, And Response (SOAR), Q2 2022","metaTitle":"Thank you | Forrester Now Tech : SOAR 2022","metaDescription":"Forrester Now Tech : Security Orchestration, Automation, And Response (SOAR), Q2 2022","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"research-lab","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/research-lab"
  }, {
    path: "/security-orchestration-and-automation-SOAR-platform",
    component: _9512c540,
    meta: {"title":"Security Orchestration and Automation (SOAR) Platform","description":undefined,"metaTitle":"Optimized SOAR Solutions | Quick Threat Response","metaDescription":"Security Orchestration and Automation, or SOAR, is integral to optimized cyber security threat protection. Trust Cyware for low-code SOAR solutions.","keywords":"SOAR Solutions","slug":"soar-platform","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/security-orchestration-and-automation-SOAR-platform"
  }, {
    path: "/solutions/technical-threat-intelligence-sharing-platform",
    component: _9512c540,
    meta: {"title":"Technical Threat Intelligence Sharing Platform (TIP)","description":undefined,"metaTitle":"Technical Threat Intelligence Sharing Platform (TIP)","metaDescription":"Technical Threat Intelligence Sharing Platform (TIP)","keywords":undefined,"slug":"technical-threat-intelligence-sharing-platform","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/technical-threat-intelligence-sharing-platform"
  }, {
    path: "/solutions/isac-automation-solutions",
    component: _9512c540,
    meta: {"title":"ISAC Automation Solutions","description":undefined,"metaTitle":"ISAC Automation Solutions","metaDescription":"ISAC Automation Solutions","keywords":undefined,"slug":"isac-automation-solutions","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/isac-automation-solutions"
  }, {
    path: "/solutions/threat-advisory-sharing-platform-isac",
    component: _9512c540,
    meta: {"title":"Threat Advisory Sharing Platform","description":undefined,"metaTitle":"Threat Advisory Sharing Platform","metaDescription":"Threat Advisory Sharing Platform","keywords":undefined,"slug":"threat-advisory-sharing-platform-isac","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/threat-advisory-sharing-platform-isac"
  }, {
    path: "/solutions/build-your-own-isao-isac",
    component: _9512c540,
    meta: {"title":"Build Your Own ISAO (or ISAC)","description":undefined,"metaTitle":"Build Your Own ISAO (or ISAC)","metaDescription":"Build Your Own ISAO (or ISAC)","keywords":undefined,"slug":"build-your-own-isao-isac","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/build-your-own-isao-isac"
  }, {
    path: "/solutions/isac-to-isac-automated-threat-intelligence-sharing",
    component: _9512c540,
    meta: {"title":"ISAC-to-ISAC Automated Threat Intelligence Sharing","description":undefined,"metaTitle":"ISAC-to-ISAC Automated Threat Intelligence Sharing","metaDescription":"ISAC-to-ISAC Automated Threat Intelligence Sharing","keywords":undefined,"slug":"isac-to-isac-automated-threat-intelligence-sharing","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/isac-to-isac-automated-threat-intelligence-sharing"
  }, {
    path: "/solutions/lightweight-threat-intelligence-platform-isac-isao",
    component: _9512c540,
    meta: {"title":"Lightweight Threat Intelligence Platform (TIP Lite)","description":undefined,"metaTitle":"Lightweight Threat Intelligence Platform (TIP Lite)","metaDescription":"Lightweight Threat Intelligence Platform (TIP Lite)","keywords":undefined,"slug":"lightweight-threat-intelligence-platform-isac-isao","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/lightweight-threat-intelligence-platform-isac-isao"
  }, {
    path: "/solutions/soar-platform-isac-isao",
    component: _9512c540,
    meta: {"title":"Security Orchestration and Automation (SOAR) Platform","description":undefined,"metaTitle":"Security Orchestration and Automation (SOAR) Platform","metaDescription":"Security Orchestration and Automation (SOAR) Platform","keywords":undefined,"slug":"soar-platform-isac-isao","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/soar-platform-isac-isao"
  }, {
    path: "/solutions/threat-advisory-marketplace",
    component: _9512c540,
    meta: {"title":"Threat Advisory Marketplace","description":undefined,"metaTitle":"Threat Advisory Marketplace","metaDescription":"Threat Advisory Marketplace","keywords":undefined,"slug":"threat-advisory-marketplace","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/solutions/threat-advisory-marketplace"
  }, {
    path: "/partners/technology-partners",
    component: _7a14c76c,
    meta: {"title":"Technology Partners","description":undefined,"metaTitle":"Cyber Technology Partner | Cyware Technology Partner","metaDescription":"Want to become a cyber technology partner with Cyware? Get more insights on why you should partner with us and what our partners are saying about us. Join with us today!","keywords":undefined,"slug":"technology-partners","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/partners/technology-partners"
  }, {
    path: "/environments",
    component: _27821153,
    meta: {"title":undefined,"description":"We provide multiple deployment options for our products, giving our customers the flexibility to make use of all the product features by choosing the best model that suits your organization's business needs.","metaTitle":" Deployment Environments","metaDescription":"Cyware's deployment environment provides multiple options for our products, giving our customers the flexibility to make use of all the product features by choosing the best model that suits your organization's business needs.","keywords":undefined,"slug":"support-environments","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/environments"
  }, {
    path: "/insights",
    component: _1ba9ad1c,
    meta: {"title":"Cyware Insights","description":"Learn how Cyware's suite of Cyber Fusion solutions is transforming the security industry with innovative technology and advanced use cases.","metaTitle":"Cyware Insights | Innovative Cyber Fusion Solutions | Threat Intelligence ","metaDescription":"Cyware Insights – Learn how Cyware Fusion solutions is transforming the security industry with innovative use cases and technology breakthroughs.","keywords":"Cyware Fusion solutions, Cyber Fusion solutions, threat intelligence technology, cyber threat intelligence, cyber fusion, Threat intelligence solutions","slug":"cyware-insights","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/insights"
  }, {
    path: "/resources/research-and-analysis",
    component: _7af14091,
    meta: {"title":"Research and Analysis","description":"Stay informed with the latest research and analysis including reports on new cyber threat actors, malware, incidents, breaches, vulnerabilities, and more.","metaTitle":"Computer Emergency Response Teams | Threat Intelligence Sharing","metaDescription":"Threat Response Sharing - Cyware’s solutions enable Computer Emergency Response Teams (CERTs) to leverage advanced automation to ingest, analyze, and share strategic and technical threat intelligence on advanced threats. Get started now!","keywords":"threat intelligence sharing, computer emergency response team, cyber emergency response team, threat intelligence sharing platform, threat sharing platform, cyber intelligence sharing platform","slug":"research-and-analysis","headerImg":"\u002Fblog\u002Fresearch-analysis.svg","showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/research-and-analysis"
  }, {
    path: "/resources/threat-briefings/daily-threat-briefing",
    component: _9d04f248,
    meta: {"title":"Daily Threat Briefing","description":"Start your day with a concise and succinct digest of the most important security happenings of the past 24 hours delivered straight to your inbox Monday through Friday.","metaTitle":"Real-Time Threat Intelligence | Get The Daily Threat Briefing","metaDescription":"Start your day with a concise and succinct digest of the most important security happenings of the past 24 hours delivered straight to your inbox Monday through Friday.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"daily-threat-briefing","headerImg":"\u002Fblog\u002Fdaily-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/daily-threat-briefing"
  }, {
    path: "/resources/threat-briefings/weekly-threat-briefing",
    component: _9d04f248,
    meta: {"title":"Weekly Threat Briefing","description":"Learn how the cybersecurity world was shaped throughout the week with our weekly briefings on new threats, breaches, and latest advances in cyber technology delivered directly to your inbox.","metaTitle":"Real-Time Threat Intelligence | Get The Weekly Threat Briefing ","metaDescription":"Learn how the cybersecurity world was shaped throughout the week with our weekly briefings on new threats, breaches, and latest advances in cyber technology delivered directly to your inbox.","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"weekly-threat-briefing","headerImg":"\u002Fblog\u002Fweekly-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/weekly-threat-briefing"
  }, {
    path: "/resources/threat-briefings/monthly-threat-briefing",
    component: _9d04f248,
    meta: {"title":"Monthly Threat Briefing","description":"Keep a tab on the overall monthly trends of the security landscape with our detailed report on new cyber threats, breaches, incidents, vulnerabilities, and scams.","metaTitle":" Monthly New Cyber Threat, Breaches, Incidents, Vulnerabilities, and Scams","metaDescription":"Monthly Cyber Threat Briefing – Several new malware, cybersecurity activities, vulnerabilities were reported over months. Here are the detailed list of Cyber Security industry new Threat, Breaches, Incidents, Vulnerabilities, and Scams that were reported on every month. Check out now!","keywords":"Real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts","slug":"monthly-threat-briefing","headerImg":"\u002Fblog\u002Fmonthly-threat-briefing.svg","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/resources/threat-briefings/monthly-threat-briefing"
  }, {
    path: "/cyware-fusion-center",
    component: _09f4e604,
    meta: {"title":"Cyware Fusion Center","description":undefined,"metaTitle":"Cyware Fusion Center","metaDescription":"Cyware Fusion Center","keywords":"View our library of videos to learn more about threat intelligence automation, threat response, security orchestration, cyber fusion, and more.","slug":"cyber-fusion-center","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/cyware-fusion-center"
  }, {
    path: "/educational-guides/cyber-fusion-and-threat-response",
    component: _674b58ed,
    meta: {"title":"Cyber Fusion Center Guide","description":undefined,"metaTitle":"Cyber Fusion Center Guide","metaDescription":"Cyber Fusion Center Guide","keywords":undefined,"slug":"cfc-guide","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/educational-guides/cyber-fusion-and-threat-response"
  }, {
    path: "/blog/:id",
    component: _d25c8258,
    meta: {"title":"Cyware Blog","description":undefined,"metaTitle":undefined,"metaDescription":undefined,"keywords":undefined,"slug":"blog","headerImg":"\u002Fblog\u002Fblog.png","showTransparentHeader":true,"hideHeaderFooter":undefined,"ignoreSitemap":true,"auth":undefined,"reference":undefined},
    name: "/blog/:id"
  }, {
    path: "/responsible-vulnerability-disclosure",
    component: _26bccfa4,
    meta: {"title":"Responsible Vulnerability Disclosure","description":"Responsible Vulnerability Disclosure","metaTitle":" Responsible Vulnerability Disclosure","metaDescription":"Cyware appreciates the important work of security researchers who identify and report potential vulnerabilities in Cyware products. If you believe that you have discovered a vulnerability in a Kaspersky, please report it to us right away.","keywords":"Responsible Vulnerability Disclosure","slug":"responsible-vulnerability-disclosure","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/responsible-vulnerability-disclosure"
  }, {
    path: "/use-case",
    component: _546a8590,
    meta: {"title":"Use Cases","description":undefined,"metaTitle":"Use Cases for Cyber Security","metaDescription":"Cyware identifies the potential attacks & detects insider threats or malicious activities & provides unique solutions to help you quickly and easily to handle cyber attacks, threat detection and management, phishing, threat intel automation, response, malware attacks, and more.","keywords":"Managed Security Service Providers, cyber fusion solutions, threat intelligence, cyber security solutions          ","slug":"use-case","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case"
  }, {
    path: "/use-case/ransomware-alert-response",
    component: _4a7df021,
    meta: {"title":"Ransomware Alert Response","description":undefined,"metaTitle":"Ransomware Alert Response | Ransomware Response Playbook | Threat Response","metaDescription":"Ransomware Attack Alert - Ransomware response playbook process is an effective solution for detecting the Ransomware attacks in their early stages, it plays an important role in countering the threat at machine speed instead of relying on slower, manual processes. Contact now!","keywords":"ransomware alert, ransomware attack alert, ransomware response playbook, threat response","slug":"ransomware-alert-response","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/ransomware-alert-response"
  }, {
    path: "/use-case/malware-alert-investigation",
    component: _4a7df021,
    meta: {"title":"Malware Alert Investigation","description":undefined,"metaTitle":"Malware Alert Investigation | Malware Attack Alert | Threat Intelligence","metaDescription":"Malware Attack Alert - Using malware alert investigation playbook, a malware attack can be automatically detected, investigated, and contained even before it spreads and damages your network. Contact now!","keywords":"malware alert, malware attack, threat intelligence, malicious software, threat response, Situational Awareness","slug":"malware-alert-investigation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/malware-alert-investigation"
  }, {
    path: "/use-case/dos-alert-mitigation",
    component: _4a7df021,
    meta: {"title":"Denial-of-Service (DoS) Alert Mitigation","description":undefined,"metaTitle":"DoS Alert Automation Playbook | Dos Attack Alert | Threat Response","metaDescription":"DoS Attack Alert - Security teams can utilize automated DoS response playbooks to break the chain of DoS attacks, which performs the incident investigation, indicator enrichment, and response actions in a parallelized manner. Contact Now!","keywords":"dos alert, dos attack, threat intelligence platform, cyber security intelligence, cyber threat analysis, incident response","slug":"dos-alert-mitigation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/dos-alert-mitigation"
  }, {
    path: "/use-case/automated-threat-intelligence-enrichment",
    component: _4a7df021,
    meta: {"title":"Automated Threat Intelligence Enrichment","description":undefined,"metaTitle":"Automated Threat Intelligence Enrichment","metaDescription":"Threat intelligence enrichment is critical for cybersecurity, but it takes time. Automated threat intelligence enrichment can analyze threat data in seconds.","keywords":"automated threat intelligence, cyber security operations, threat intelligence, cyber threat intelligence, cyber security intelligence, cyber threat analysis","slug":"automated-threat-intelligence-enrichment","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/automated-threat-intelligence-enrichment"
  }, {
    path: "/use-case/spearphishing-response-automation",
    component: _4a7df021,
    meta: {"title":"Spearphishing Response Automation","description":undefined,"metaTitle":"Spearphishing Response Automation | Automated Spearphishing Response","metaDescription":"Incident Response - Spearphishing is one of the most common attack vectors for cybercriminals to infiltrate organizations globally. The automated playbooks standardize the response process from detection to blocking of the malicious indicators. Contact now!     ","keywords":"spearphishing attack, phishing attack, spearphishing emails, spearphishing response, incident response   ","slug":"spearphishing-response-automation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/spearphishing-response-automation"
  }, {
    path: "/use-case/web-defacement-response-automation",
    component: _4a7df021,
    meta: {"title":"Web Defacement Response Automation","description":undefined,"metaTitle":"Web Defacement Attack Response | Automated Incident Response","metaDescription":"Threat Response - Security teams can leverage automated playbooks to detect and respond to web defacement attacks, that standardize the response process from detection to response and remediation actions at machine speed. Contact now!","keywords":"threat response, cyber situational awareness, situational awareness, website defacement attack, web application vulnerabilities, web vulnerability","slug":"web-defacement-response-automation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/web-defacement-response-automation"
  }, {
    path: "/use-case/unstructured-threat-intel-advisory-automation",
    component: _4a7df021,
    meta: {"title":"Unstructured Threat Intel Advisory Automation","description":undefined,"metaTitle":"Unstructured Threat Intel Automation | Security Automation | Threat Indicators","metaDescription":"Security Automation - Improve the analysis of potential threats by leverage security automation to automatically extract various threat indicators and attack patterns from unstructured documents. Contact now!","keywords":"security automation, threat indicators, cyber security automation, Threat Intelligence, threat information","slug":"unstructured-threat-intel-advisory-automation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/unstructured-threat-intel-advisory-automation"
  }, {
    path: "/use-case/remote-to-local-exploit-response-automation",
    component: _4a7df021,
    meta: {"title":"Remote to Local Exploit Response Automation","description":undefined,"metaTitle":"Remote to Local Exploit Response | Remote Exploit Attack | Incident Response","metaDescription":"Remote Exploit Attack - Detection time of remote exploit attack is very high, by automated playbooks can reduce the overall dwell detection time and the mean-time-to-respond by parallelizing incident investigation, enrichment, analysis, and containment processes. Contact Now!","keywords":"incident response, security incident, cyber incident response, remote exploit attack","slug":"remote-to-local-exploit-response-automation","headerImg":undefined,"showTransparentHeader":undefined,"hideHeaderFooter":undefined,"ignoreSitemap":undefined,"auth":undefined,"reference":undefined},
    name: "/use-case/remote-to-local-exploit-response-automation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
