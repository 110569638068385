<template>
  <div>
    <section class="">
      <b-container>
        <b-row class="h-100 align-items-center">
          <b-col cols="12" class="text-center">
            <h2
              class="cy-heading-2 cy-text-nile-blue cy-line-height-40 cy-font-weight-800"
            >
              404 ERROR
            </h2>
            <h2 class="cy-heading-2 cy-text-nile-blue cy-line-height-40">
              Looks like this page is missing or no longer available. <br />Keep
              calm and find your way back <nuxt-link to="/">home</nuxt-link>.
            </h2>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-img src="/not-found.png"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>
