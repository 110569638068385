var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('input', {
    staticClass: "profile--input c_code",
    attrs: {
      "placeholder": "+"
    },
    domProps: {
      "value": _vm.value.country_code
    },
    on: {
      "input": function (event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, {
          country_code: event.target.value
        }));
      }
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "pr-2"
  }, [_vm._v("/")]), _vm._v(" "), _c('input', {
    staticClass: "profile--input ph_number",
    attrs: {
      "type": "number",
      "placeholder": "Your Contact Number"
    },
    domProps: {
      "value": _vm.value.ph_number
    },
    on: {
      "input": function (event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, {
          ph_number: event.target.value
        }));
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }