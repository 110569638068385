<template>
  <footer v-if="menus" id="footer" class="position-relative pt-5">
    <b-container>
      <b-row
        class="cy-border-top-light-blue cy-border-bottom-light-blue pt-5 pb-3 cy-pb-xs-0"
      >
        <b-col :cols="12" md="">
          <h2
            class="cy-heading-3 cy-text-nile-blue cy-font-weight-800 letter-spacing-1"
          >
            {{ menus['products'].title }}
          </h2>
          <ul class="list-unstyled">
            <b-row>
              <li
                v-for="(menu, listId) in computedArray(
                  menus['products'].children,
                  'products'
                ).slice(0, 3)"
                :key="listId"
                class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2 col-md-12"
                :class="listId === 0 ? 'col-12' : 'col-6'"
              >
                <span>
                  <nuxt-link
                    v-if="menu.link"
                    :to="menu.link"
                    class="cy-text-nile-blue cy-font-weight-700 cy-line-height-21 text-decoration-underline-on-hover"
                    >{{ menu.title }}</nuxt-link
                  >

                  <li
                    v-for="(product, i) in menu.children"
                    :key="i"
                    class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
                  >
                    <nuxt-link
                      :to="getHyperLink(product.slug)"
                      class="text-decoration-underline-on-hover cy-text-nile-blue"
                    >
                      {{ product.name }}
                    </nuxt-link>
                  </li>
                </span>
              </li>
            </b-row>
          </ul>
          <b-row>
            <b-col>
              <ul class="list-unstyled">
                <div
                  class="cy-font-weight-600 cy-line-height-21 cy-text-grey-shade-1 cy-heading-xs-4 col-6 pl-0 col-md-12"
                >
                  {{ menus['products'].children[3].title }}
                </div>
                <b-row>
                  <li
                    v-for="(product, i) in menus['products'].children[3]
                      .children"
                    :key="i"
                    class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2 col-6 col-md-12"
                  >
                    <nuxt-link
                      :to="getHyperLink(product.slug)"
                      class="text-decoration-underline-on-hover cy-text-nile-blue"
                    >
                      {{ product.name }}
                    </nuxt-link>
                  </li>
                </b-row>
              </ul>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-for="(item, index) in Object.keys(menus).filter(
            (item) => item !== 'extra' && item !== 'products'
          )"
          :key="index"
          :cols="'6'"
          :md="''"
        >
          <h2
            class="cy-heading-3 cy-text-nile-blue cy-font-weight-800 letter-spacing-1"
          >
            {{ menus[item].title }}
          </h2>
          <ul class="list-unstyled">
            <li
              v-for="(menu, listId) in computedArray(
                menus[item].children,
                item
              )"
              :key="listId"
              class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
            >
              <span v-if="menu.slug === 'social'">
                <a :href="menu.link" target="_blank" class="cy-text-nile-blue">
                  <span>{{ menu.title }}</span>
                </a>
                <span v-if="menu.app_icons" class="text-decoration-none">
                  <span
                    v-for="(app, appIndex) in menu.app_icons"
                    :key="appIndex"
                    class="px-1"
                  >
                    <a :href="app.link" target="_blank" rel="noreferrer">
                      <b-img-lazy :src="app.icon" fluid alt="Cyware Social" />
                    </a>
                  </span>
                </span>
              </span>
              <span v-else>
                <hr v-if="menu.divider" class="my-0 cy-border-top-light-blue" />
                <div v-else :to="menu.link" class="cy-text-nile-blue">
                  <span>
                    <a
                      v-if="menu.slug === 'videos'"
                      target="_blank"
                      class="text-decoration-underline-on-hover cy-text-nile-blue"
                      href="https://www.brighttalk.com/channel/19925/?utm_source=Cyware&utm_medium=web&utm_campaign=19925"
                      >{{ menu.name }}</a
                    >
                    <nuxt-link
                      v-else
                      :to="getHyperLink(menu.slug)"
                      class="text-decoration-underline-on-hover cy-text-nile-blue"
                    >
                      {{ menu.name }}
                    </nuxt-link>
                    <span
                      v-if="menu.careerTag"
                      class="px-2 cy-bg-section-bg cy-font-weight-600 rounded cy-text-primary-blue cy-heading-6 text-decoration-none"
                      >We’re Hiring</span
                    >
                  </span>
                </div>
              </span>
            </li>
            <li
              v-if="item === 'partners'"
              class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
            >
              <a
                href="https://go.cyware.com/registerdeal"
                class="text-decoration-underline-on-hover cy-text-nile-blue"
                target="_blank"
                >Register a Deal</a
              >
            </li>
            <li
              v-if="item === 'partners'"
              class="cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
            >
              <a
                href="https://cyware.amp.vg/login"
                class="text-decoration-underline-on-hover cy-text-nile-blue"
                target="_blank"
                >CywareOne Login</a
              >
            </li>
            <!-- <li
              v-if="item === 'products'"
              class="
                cy-heading-5
                cy-text-nile-blue
                cy-font-weight-400
                cy-line-height-20
                py-2
              "
            >
              <nuxt-link :to="getHyperLink('demo-page')" class="cy-heading-5">
                Get a Demo <i class="cyicon-arrow-right heading-6" />
              </nuxt-link>
            </li> -->
          </ul>
        </b-col>
      </b-row>
      <b-row
        class="align-items-center justify-content-between align-self-center border-bottom-light-blue py-2 cy-pt-xs-2"
      >
        <b-col md="auto" class="text-center mb-2 mb-md-0 cy-mb-xs-1">
          <a
            class="cursor-pointer cy-font-weight-600 text-decoration-none cy-text-primary-blue"
            href="javascript:void(0)"
            @click="renderContactForm"
            >Get in touch with us now!</a
          >
        </b-col>
        <b-col
          md="auto"
          class="text-center mb-2 mb-md-0 mr-5 cy-mr-xs-0 cy-mb-xs-1"
        >
          <a
            class="cursor-pointer cy-font-weight-600 text-decoration-none"
            href="tel:+18556929927"
            target="_blank"
            aria-label="Cyware Phone"
          >
            <b-img-lazy src="~/static/footer/phone.svg" alt="Cyware Phone" />
            <span class="align-middle cy-text-primary-blue"
              >1-855-692-9927</span
            >
          </a>
        </b-col>
        <b-col class="text-center my-2 cy-mb-xs-1" cols="12" md="auto">
          <ul class="list-unstyled mb-0">
            <li
              class="cy-heading-6 cy-text-nile-blue cy-font-weight-400 cy-line-height-20"
            >
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item px-1">
                  <a
                    href="https://twitter.com/CywareCo"
                    class="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Cyware Twitter"
                  >
                    <b-img-lazy
                      src="~/static/footer/twitter_icon_footer.svg"
                      class="cy-footer-icons"
                      alt="Cyware Twitter"
                    />
                  </a>
                </li>
                <li class="list-inline-item px-1">
                  <a
                    href="https://www.linkedin.com/company/10407612/"
                    class="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Cyware LinkedIn"
                  >
                    <b-img-lazy
                      src="~/static/footer/linkedin_icon_footer.svg"
                      class="cy-footer-icons"
                      alt="Cyware LinkedIn"
                    />
                  </a>
                </li>
                <li class="list-inline-item px-1">
                  <a
                    href="https://www.youtube.com/channel/UCBPcVKjzXijVkBWwV3wNjag/featured"
                    class="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Cyware YouTube"
                  >
                    <b-img-lazy
                      src="~/static/footer/youtube_icon_footer.svg"
                      class="cy-footer-icons"
                      alt="Cyware YouTube"
                    />
                  </a>
                </li>
                <li class="list-inline-item px-1">
                  <a
                    href="https://www.facebook.com/Cywareco/"
                    class="text-decoration-none"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Cyware Facebook"
                  >
                    <b-img-lazy
                      src="~/static/footer/facebook_icon_footer.svg"
                      class="cy-footer-icons"
                      alt="Cyware Facebook"
                    />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col
          cols="12"
          md="4"
          class="text-center text-md-left order-2 order-md-1 cy-mb-xs-1"
        >
          <a
            href="/cyber-security-news-articles"
            target="_blank"
            class="cy-heading-5 text-decoration-none"
            aria-label="Cyware Social"
          >
            <b-img-lazy
              class="cy-footer-logo pr-3"
              src="~/static/social.svg"
              width="110"
              height="35"
              alt="Cyware Social"
            />
            <i class="cyicon-external cy-text-primary-blue" />
          </a>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="text-center text-md-center order-1 order-md-2 cy-mb-xs-2"
        >
          <b-img
            v-for="(img, index) in footerImages"
            :key="index"
            :src="require(`~/static/footer/${img}`)"
            class="mx-2"
            alt="Cyware Social"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="text-center text-md-right pt-4 pt-md-2 cy-font-weight-600 cy-text-nile-blue cy-heading-5 border-top-light-blue order-3 order-md-3"
        >
          <a
            class="pr-2 cursor-pointer cy-footer__color hover-effect cy-text-nile-blue"
            href="/legal/website-terms-of-use/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms of Use
          </a>
          <a
            class="px-2 cursor-pointer cy-footer__color hover-effect cy-text-nile-blue"
            href="/legal/privacy-policy/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>
          <span class="pl-2 cy-footer__color">&copy; 2023</span>
        </b-col>
      </b-row>
    </b-container>
    <cy-contact-form></cy-contact-form>
  </footer>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
// import HubSpotFormMixin from '@/plugins/HubSpotFormMixin'
import ALL_MENUS from '@/config/menuData'
export default {
  components: {
    CyContactForm: () => import('@/components/forms/CyContactForm')
  },
  // mixins: [HubSpotFormMixin],
  data() {
    const allMenus = {...ALL_MENUS};
    delete allMenus.quarterback;
    return {
      menus: allMenus,
      footerImages: ['iso.svg', 'soc.svg', 'fedramp.svg'],
      gtag: ` gtag('config', 'AW-712433333/CEcyCP6BuP4BELW929MC', {
    'phone_conversion_number': '1-855-692-9927'
  });`
    }
  },
  head() {
    return {
      script: [
        {
          type: 'text/javascript',
          innerHTML: this.gtag
        }
      ]
    }
  },
  methods: {
    onSubscribeClick() {
      EventBus.$emit('focus-subscribe')
    },
    computedArray(menus, slug) {
      switch (slug) {
        case 'products': {
          return menus.filter((x) => x.slug !== 'overview')
        }
        case 'company': {
          return menus.filter((x) => x.slug !== 'leadership-bio')
        }
        case 'solutions': {
          return menus.filter((x) =>
            [
              'solutions-overview',
              'use-case',
              'enterprise',
              'isacs',
              'isac-member-sharing',
              'certs',
              'mssps'
            ].includes(x.slug)
          )
        }
        case 'integrations': {
          const currentIndex = menus.findIndex((item) => item.slug === 'misp')
          const newItem = {
            divider: true
          }
          return menus
            .slice(0, currentIndex + 1)
            .concat(newItem, menus.slice(currentIndex + 1))
        }
        default: {
          return menus
        }
      }
    },
    renderContactForm() {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7288424',
          formId: 'ceb15dc5-d61c-489a-9ff6-88e649fb4fa1',
          target: '#contactForm'
        })
      }
      this.$bvModal.show('contact-form')
    }
  }
}
</script>
