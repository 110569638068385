/* eslint-disable array-callback-return */
const menus = require('./menuData')

module.exports = () => {
  const pages = []
  Object.keys(menus).map((item) => {
    const mainItem = menus[item]
    if (mainItem.page) {
      pages.push(buildMeta(mainItem))
    }
    if (mainItem.children) {
      mainItem.children.map((item1) => {
        if (item1.page) {
          pages.push(buildMeta(item1))
        }
        if (item1.children) {
          item1.children.map((item2) => {
            if (item2.page) {
              pages.push(buildMeta(item2))
            }
            if (item2.children) {
              item2.children.map((item3) => {
                if (item3.page) {
                  pages.push(buildMeta(item3))
                }
              })
            }
          })
        }
      })
    }
  })
  return pages
}

function buildMeta(page) {
  const meta = {
    title: page.title,
    description: page.description,
    metaTitle: page.metaTitle,
    metaDescription: page.metaDescription,
    keywords: page.keywords,
    slug: page.slug,
    headerImg: page.headerImg,
    showTransparentHeader: page.showTransparentHeader,
    hideHeaderFooter: page.hideHeaderFooter,
    ignoreSitemap: page.ignoreSitemap,
    auth: page.auth,
    reference: page.reference
  }
  return { meta, ...page }
}
