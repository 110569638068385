var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.menus ? _c('footer', {
    staticClass: "position-relative pt-5",
    attrs: {
      "id": "footer"
    }
  }, [_c('b-container', [_c('b-row', {
    staticClass: "cy-border-top-light-blue cy-border-bottom-light-blue pt-5 pb-3 cy-pb-xs-0"
  }, [_c('b-col', {
    attrs: {
      "cols": 12,
      "md": ""
    }
  }, [_c('h2', {
    staticClass: "cy-heading-3 cy-text-nile-blue cy-font-weight-800 letter-spacing-1"
  }, [_vm._v("\n          " + _vm._s(_vm.menus['products'].title) + "\n        ")]), _vm._v(" "), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('b-row', _vm._l(_vm.computedArray(_vm.menus['products'].children, 'products').slice(0, 3), function (menu, listId) {
    return _c('li', {
      key: listId,
      staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2 col-md-12",
      class: listId === 0 ? 'col-12' : 'col-6'
    }, [_c('span', [menu.link ? _c('nuxt-link', {
      staticClass: "cy-text-nile-blue cy-font-weight-700 cy-line-height-21 text-decoration-underline-on-hover",
      attrs: {
        "to": menu.link
      }
    }, [_vm._v(_vm._s(menu.title))]) : _vm._e(), _vm._v(" "), _vm._l(menu.children, function (product, i) {
      return _c('li', {
        key: i,
        staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
      }, [_c('nuxt-link', {
        staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
        attrs: {
          "to": _vm.getHyperLink(product.slug)
        }
      }, [_vm._v("\n                    " + _vm._s(product.name) + "\n                  ")])], 1);
    })], 2)]);
  }), 0)], 1), _vm._v(" "), _c('b-row', [_c('b-col', [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('div', {
    staticClass: "cy-font-weight-600 cy-line-height-21 cy-text-grey-shade-1 cy-heading-xs-4 col-6 pl-0 col-md-12"
  }, [_vm._v("\n                " + _vm._s(_vm.menus['products'].children[3].title) + "\n              ")]), _vm._v(" "), _c('b-row', _vm._l(_vm.menus['products'].children[3].children, function (product, i) {
    return _c('li', {
      key: i,
      staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2 col-6 col-md-12"
    }, [_c('nuxt-link', {
      staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
      attrs: {
        "to": _vm.getHyperLink(product.slug)
      }
    }, [_vm._v("\n                    " + _vm._s(product.name) + "\n                  ")])], 1);
  }), 0)], 1)])], 1)], 1), _vm._v(" "), _vm._l(Object.keys(_vm.menus).filter(function (item) {
    return item !== 'extra' && item !== 'products';
  }), function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "cols": '6',
        "md": ''
      }
    }, [_c('h2', {
      staticClass: "cy-heading-3 cy-text-nile-blue cy-font-weight-800 letter-spacing-1"
    }, [_vm._v("\n          " + _vm._s(_vm.menus[item].title) + "\n        ")]), _vm._v(" "), _c('ul', {
      staticClass: "list-unstyled"
    }, [_vm._l(_vm.computedArray(_vm.menus[item].children, item), function (menu, listId) {
      return _c('li', {
        key: listId,
        staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
      }, [menu.slug === 'social' ? _c('span', [_c('a', {
        staticClass: "cy-text-nile-blue",
        attrs: {
          "href": menu.link,
          "target": "_blank"
        }
      }, [_c('span', [_vm._v(_vm._s(menu.title))])]), _vm._v(" "), menu.app_icons ? _c('span', {
        staticClass: "text-decoration-none"
      }, _vm._l(menu.app_icons, function (app, appIndex) {
        return _c('span', {
          key: appIndex,
          staticClass: "px-1"
        }, [_c('a', {
          attrs: {
            "href": app.link,
            "target": "_blank",
            "rel": "noreferrer"
          }
        }, [_c('b-img-lazy', {
          attrs: {
            "src": app.icon,
            "fluid": "",
            "alt": "Cyware Social"
          }
        })], 1)]);
      }), 0) : _vm._e()]) : _c('span', [menu.divider ? _c('hr', {
        staticClass: "my-0 cy-border-top-light-blue"
      }) : _c('div', {
        staticClass: "cy-text-nile-blue",
        attrs: {
          "to": menu.link
        }
      }, [_c('span', [menu.slug === 'videos' ? _c('a', {
        staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
        attrs: {
          "target": "_blank",
          "href": "https://www.brighttalk.com/channel/19925/?utm_source=Cyware&utm_medium=web&utm_campaign=19925"
        }
      }, [_vm._v(_vm._s(menu.name))]) : _c('nuxt-link', {
        staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
        attrs: {
          "to": _vm.getHyperLink(menu.slug)
        }
      }, [_vm._v("\n                    " + _vm._s(menu.name) + "\n                  ")]), _vm._v(" "), menu.careerTag ? _c('span', {
        staticClass: "px-2 cy-bg-section-bg cy-font-weight-600 rounded cy-text-primary-blue cy-heading-6 text-decoration-none"
      }, [_vm._v("We’re Hiring")]) : _vm._e()], 1)])])]);
    }), _vm._v(" "), item === 'partners' ? _c('li', {
      staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
    }, [_c('a', {
      staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
      attrs: {
        "href": "https://go.cyware.com/registerdeal",
        "target": "_blank"
      }
    }, [_vm._v("Register a Deal")])]) : _vm._e(), _vm._v(" "), item === 'partners' ? _c('li', {
      staticClass: "cy-heading-5 cy-text-nile-blue cy-font-weight-400 cy-line-height-20 py-2"
    }, [_c('a', {
      staticClass: "text-decoration-underline-on-hover cy-text-nile-blue",
      attrs: {
        "href": "https://cyware.amp.vg/login",
        "target": "_blank"
      }
    }, [_vm._v("CywareOne Login")])]) : _vm._e()], 2)]);
  })], 2), _vm._v(" "), _c('b-row', {
    staticClass: "align-items-center justify-content-between align-self-center border-bottom-light-blue py-2 cy-pt-xs-2"
  }, [_c('b-col', {
    staticClass: "text-center mb-2 mb-md-0 cy-mb-xs-1",
    attrs: {
      "md": "auto"
    }
  }, [_c('a', {
    staticClass: "cursor-pointer cy-font-weight-600 text-decoration-none cy-text-primary-blue",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.renderContactForm
    }
  }, [_vm._v("Get in touch with us now!")])]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center mb-2 mb-md-0 mr-5 cy-mr-xs-0 cy-mb-xs-1",
    attrs: {
      "md": "auto"
    }
  }, [_c('a', {
    staticClass: "cursor-pointer cy-font-weight-600 text-decoration-none",
    attrs: {
      "href": "tel:+18556929927",
      "target": "_blank",
      "aria-label": "Cyware Phone"
    }
  }, [_c('b-img-lazy', {
    attrs: {
      "src": require("static/footer/phone.svg"),
      "alt": "Cyware Phone"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "align-middle cy-text-primary-blue"
  }, [_vm._v("1-855-692-9927")])], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center my-2 cy-mb-xs-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "cy-heading-6 cy-text-nile-blue cy-font-weight-400 cy-line-height-20"
  }, [_c('ul', {
    staticClass: "list-unstyled list-inline"
  }, [_c('li', {
    staticClass: "list-inline-item px-1"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": "https://twitter.com/CywareCo",
      "target": "_blank",
      "rel": "noreferrer",
      "aria-label": "Cyware Twitter"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-footer-icons",
    attrs: {
      "src": require("static/footer/twitter_icon_footer.svg"),
      "alt": "Cyware Twitter"
    }
  })], 1)]), _vm._v(" "), _c('li', {
    staticClass: "list-inline-item px-1"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": "https://www.linkedin.com/company/10407612/",
      "target": "_blank",
      "rel": "noreferrer",
      "aria-label": "Cyware LinkedIn"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-footer-icons",
    attrs: {
      "src": require("static/footer/linkedin_icon_footer.svg"),
      "alt": "Cyware LinkedIn"
    }
  })], 1)]), _vm._v(" "), _c('li', {
    staticClass: "list-inline-item px-1"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": "https://www.youtube.com/channel/UCBPcVKjzXijVkBWwV3wNjag/featured",
      "target": "_blank",
      "rel": "noreferrer",
      "aria-label": "Cyware YouTube"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-footer-icons",
    attrs: {
      "src": require("static/footer/youtube_icon_footer.svg"),
      "alt": "Cyware YouTube"
    }
  })], 1)]), _vm._v(" "), _c('li', {
    staticClass: "list-inline-item px-1"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": "https://www.facebook.com/Cywareco/",
      "target": "_blank",
      "rel": "noreferrer",
      "aria-label": "Cyware Facebook"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-footer-icons",
    attrs: {
      "src": require("static/footer/facebook_icon_footer.svg"),
      "alt": "Cyware Facebook"
    }
  })], 1)])])])])])], 1), _vm._v(" "), _c('b-row', {
    staticClass: "py-2"
  }, [_c('b-col', {
    staticClass: "text-center text-md-left order-2 order-md-1 cy-mb-xs-1",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('a', {
    staticClass: "cy-heading-5 text-decoration-none",
    attrs: {
      "href": "/cyber-security-news-articles",
      "target": "_blank",
      "aria-label": "Cyware Social"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-footer-logo pr-3",
    attrs: {
      "src": require("static/social.svg"),
      "width": "110",
      "height": "35",
      "alt": "Cyware Social"
    }
  }), _vm._v(" "), _c('i', {
    staticClass: "cyicon-external cy-text-primary-blue"
  })], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "text-center text-md-center order-1 order-md-2 cy-mb-xs-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, _vm._l(_vm.footerImages, function (img, index) {
    return _c('b-img', {
      key: index,
      staticClass: "mx-2",
      attrs: {
        "src": require(`~/static/footer/${img}`),
        "alt": "Cyware Social"
      }
    });
  }), 1), _vm._v(" "), _c('b-col', {
    staticClass: "text-center text-md-right pt-4 pt-md-2 cy-font-weight-600 cy-text-nile-blue cy-heading-5 border-top-light-blue order-3 order-md-3",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('a', {
    staticClass: "pr-2 cursor-pointer cy-footer__color hover-effect cy-text-nile-blue",
    attrs: {
      "href": "/legal/website-terms-of-use/",
      "target": "_blank",
      "rel": "noreferrer noopener"
    }
  }, [_vm._v("\n          Terms of Use\n        ")]), _vm._v(" "), _c('a', {
    staticClass: "px-2 cursor-pointer cy-footer__color hover-effect cy-text-nile-blue",
    attrs: {
      "href": "/legal/privacy-policy/",
      "target": "_blank",
      "rel": "noreferrer noopener"
    }
  }, [_vm._v("\n          Privacy Policy\n        ")]), _vm._v(" "), _c('span', {
    staticClass: "pl-2 cy-footer__color"
  }, [_vm._v("© 2023")])])], 1)], 1), _vm._v(" "), _c('cy-contact-form')], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }