<template>
  <div />
</template>

<script>
import { DRIFT_CHAT_BOT } from '@/config/head'
export default {
  data() {
    return {
      structuredData: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Cyber Fusion Center | Threat Intelligence Solution Company | Cyware',
        logo: 'https://production.cyware.com/enterprise/cyware_logo.png',
        url: 'https://cyware.com',
        sameAs: [
          'https://www.facebook.com/Cywareco/',
          'https://www.linkedin.com/company/cyware',
          'https://twitter.com/cywareco'
        ],
        description:
          'Cyber Fusion Center - Cyware offers innovative, real-time cyber fusion solutions for Strategic and Tactical Threat Intelligence Sharing, Threat Response and Security Automation. Get a Demo Now!'
      },
      gtmJs: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W8LZ7GT');`,
      googleTag: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'AW-712433333');`,
      driftChatBot: DRIFT_CHAT_BOT
    }
  },
  head(context) {
    const meta = {
      title: this.$route.meta.metaTitle
        ? this.$route.meta.metaTitle + ' | Cyware'
        : 'Cybersecurity Automation from Cyware',
      description: this.$route.meta.metaDescription
        ? this.htmlToText(this.$route.meta.metaDescription)
        : 'Cyber attacks are increasingly relying on automation. Cybersecurity automation from Cyware improves your threat detection and response capabilities.',
      keywords: this.$route.meta.keywords
        ? this.$route.meta.keywords
        : 'Cyber fusion solutions, Cyber fusion center, Threat intelligence solutions, Threat response, Security automation, real-time cyber alerts, Real-time cyber security alerts, cyber security, real time alerts, cyber alerts, cyber security products, cyber security companies, best cyber security companies, cyber security operations center, threat intelligence solution',
      author: 'Cyware Labs',
      publisher: 'Cyware Labs',
      site_name: 'Cyware Labs',
      url: process.env.BLOG_API_URL + this.$route.fullPath,
      image:
        process.env.META_IMAGE ||
        'https://production.cyware.com/enterprise/ogimage.png'
    }
    return {
      bodyAttrs: {
        class: 'bg-white'
      },
      title: meta.title,
      link: [
        {
          rel: 'canonical',
          href: process.env.BLOG_API_URL + this.$route.path
        }
      ],
      meta: [
        {
          name: 'apple-mobile-web-app-title',
          hid: 'apple-mobile-web-app-title',
          content: meta.title
        },
        {
          hid: 'title',
          name: 'title',
          content: meta.title
        },
        {
          hid: 'description',
          name: 'description',
          content: meta.description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: meta.keywords
        },
        {
          hid: 'author',
          name: 'author',
          content: meta.author
        },
        {
          hid: 'publisher',
          name: 'publisher',
          content: meta.publisher
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: meta.description
        },
        { hid: 'og:image', property: 'og:image', content: meta.image },
        { hid: 'og:title', property: 'og:title', content: meta.title },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: meta.site_name
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: meta.url
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary'
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: meta.title
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: meta.description
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: meta.image
        }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json'
        },
        {
          type: 'text/javascript',
          innerHTML: this.gtmJs
        },
        {
          type: 'text/javascript',
          innerHTML: this.googleTag
        },
        {
          type: 'text/javascript',
          innerHTML: this.driftChatBot
        }
      ]
    }
  },
  methods: {
    htmlToText(html) {
      if (html) {
        return html
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/g, ' ')
          .replace(/\n/g, ' ')
          .replace(/&quot;/g, '"')
          .replace(/&#8212;/g, '-')
          .replace(/&#8220;/g, '"')
          .replace(/&#8221;/g, '"')
          .replace(/&#8216;/g, "'")
          .replace(/&#8217;/g, "'")
          .replace(/&lsquo;/g, "'")
          .replace(/&rsquo;/g, "'")
          .replace(/&#x27;/g, "'")
          .replace(/&ldquo;/g, '"')
          .replace(/&rdquo;/g, '"')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&mdash;/g, '-')
          .replace(/&ndash;/g, '-')
          .replace(/&amp;/g, '&')
      } else {
        return html
      }
    }
  }
}
</script>
