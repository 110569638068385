<template>
  <div id="app-layout">
    <cy-head />
    <cy-banner
      v-if="showTopBanner && $route.path === '/'"
      :data="bannerData['top-toast'] && bannerData['top-toast'][0]"
      @closeBanner="closeBanner()"
    />
    <cy-nav
      v-if="!hideHeaderFooter"
      :is-banner-open="showTopBanner"
      @subscribeClick="onClickSubscribe()"
      @showBackdrop="checkBackdrop"
    />
    <main
      :class="{
        'cy-bg-overlay': showBackdrop,
        'cy-container--padding-top': showTopBanner && $route.path === '/'
      }"
      role="main"
      class="cy-container"
    >
      <nuxt />
      <cy-subscriber-modal
        :from-footer="fromFooter"
        @fromFooter="fromFooter = false"
      />
      <cy-footer v-if="!hideHeaderFooter"></cy-footer>
    </main>
    <div v-if="showScrollTop" class="position-fixed bottom-right text-center">
      <a @click="scrollToTop">
        <b-img-lazy src="~/static/go_to_top.svg" />
        <p class="cy-heading-6 mb-0">Go to top</p>
      </a>
    </div>
    <cy-gdpr />
  </div>
</template>

<script>
import CyHead from '@/components/shared/Head'
import CyNav from '@/components/shared/Nav'
import CyFooter from '@/components/shared/Footer'
export default {
  components: {
    CyGdpr: () => import('@/components/miscellaneous/CyGdpr'),
    CySubscriberModal: () =>
      import('@/components/miscellaneous/CySubscriberModal'),
    CyBanner: () => import('@/components/banners/CyBanner'),
    CyHead,
    CyNav,
    CyFooter
  },
  data() {
    return {
      showScrollTop: false,
      fromFooter: false,
      showBackdrop: false,
      showTopBanner: false,
      enterpriseBannersList: ['top-toast', 'hero-ticker', 'latest-from-cyware'],
      canShowBouncePopup: false,
      isBouncePopupShown: false
    }
  },
  computed: {
    bannerData() {
      return this.$store.state.list.BANNER_DATA
    },
    hideHeaderFooter() {
      return this.$route.meta.hideHeaderFooter
    }
  },
  watch: {
    $route(newVal) {
      if (newVal) {
        if (newVal.path === '/') {
          this.getBanners()
        }
        if (typeof window.drift.page === 'function') window.drift.page()
      }
    }
  },
  mounted() {
    if (this.$route.path === '/') {
      this.getBanners()
    }
    document.addEventListener('scroll', (e) => {
      this.handleScroll()
    })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', (e) => {
      this.handleScroll()
    })
  },
  methods: {
    getPermissionForBounce() {
      const notAllowList = [
        'careers',
        'technology-partners',
        'channelpartners',
        'privacy-policy',
        'website-terms-of-use',
        'end-user-terms'
      ]
      return !notAllowList.includes(this.$route.meta.slug)
    },
    async getBanners() {
      const avlBanners = await this.$axios.$get(
        `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/available-banners`
      )
      const bannersData = {}
      const requests = []
      avlBanners.forEach((banner) => {
        if (this.enterpriseBannersList.includes(banner)) {
          requests.push(
            this.$axios.$get(
              `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/enterprise-banners/${banner}/`
            )
          )
        }
      })
      await Promise.all(requests).then((data) => {
        data.forEach((item) => {
          bannersData[item[0].banner_position_data.banner_position_slug] = item
        })
        return data
      })
      if (bannersData && bannersData['top-toast']) {
        this.showTopBanner = true
      }
      return this.$store.commit('makeData', {
        reference: 'BANNER_DATA',
        data: bannersData
      })
    },
    scrollToTop() {
      window.scroll({ top: 0, behavior: 'smooth' })
    },
    onClickSubscribe() {
      this.fromFooter = true
    },
    closeBanner() {
      this.showTopBanner = false
    },
    handleScroll() {
      if (window.scrollY > 1000) {
        this.showScrollTop = true
      } else {
        this.showScrollTop = false
      }
    },
    checkBackdrop(evt) {
      this.showBackdrop = evt
    }
  }
}
</script>
