var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cy-navbar__dropdown"
  }, [_c('div', {
    staticClass: "cy-navbar__dropdown-content"
  }, [_c('div', {
    staticClass: "cy-navbar__dropmenu cy-navbar__dropmenu--padding d-flex align-items-center"
  }, [_vm.userDetails && _vm.userDetails.user_image_url ? _c('div', {
    staticClass: "pr-3"
  }, [_c('img', {
    staticClass: "cy-navbar__profile-img",
    attrs: {
      "src": _vm.userDetails.user_image_url
    }
  })]) : _c('div', {
    staticClass: "pr-3"
  }, [_c('img', {
    staticClass: "cy-navbar__profile-img",
    attrs: {
      "src": require("static/dp-placeholder.svg")
    }
  })]), _vm._v(" "), _vm.userDetails ? _c('div', {
    staticClass: "cy-word-break cy-w-250"
  }, [_c('span', {
    staticClass: "d-block cy-navbar__profile-name mb-1"
  }, [_vm._v(_vm._s(_vm.userDetails.first_name) + "\n        ")]), _vm._v(" "), _c('span', {
    staticClass: "d-block cy-navbar__profile-mail"
  }, [_vm._v("\n          " + _vm._s(_vm.userDetails.email) + "\n        ")]), _vm._v(" "), _c('a', {
    staticClass: "dropdown--link cursor-pointer",
    style: {
      color: '#007bff !important',
      fontSize: '14px'
    },
    on: {
      "click": function ($event) {
        return _vm.profilePopup();
      }
    }
  }, [_vm._v("\n          My Profile\n        ")])]) : _vm._e()]), _vm._v(" "), _c('ul', {
    staticClass: "list-unstyled cy-navbar__dropmenu"
  }, [_c('li', [_c('nuxt-link', {
    staticClass: "dropdown--link",
    attrs: {
      "to": "/dashboard/subscriptions"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("\n          My Subscriptions\n        ")])], 1), _vm._v(" "), _c('li', [_c('nuxt-link', {
    staticClass: "dropdown--link",
    attrs: {
      "to": "/dashboard/threat-intel-feeds"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("\n          Cyware Threat Intel Feeds\n        ")])], 1)]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('ul', {
    staticClass: "list-unstyled cy-navbar__dropmenu"
  }, [_c('li', [_c('a', {
    staticClass: "dropdown--link",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Log out")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "list-unstyled cy-navbar__dropmenu"
  }, [_c('li', [_c('a', {
    staticClass: "dropdown--link",
    attrs: {
      "href": "/cyber-security-news-articles",
      "target": "_blank"
    }
  }, [_vm._v("Go to Cyware Social\n          "), _c('i', {
    staticClass: "cyicon-external-link text-primary ml-4"
  })])])]);

}]

export { render, staticRenderFns }