var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cy-mainmenu",
    style: _vm.computedStyle,
    on: {
      "mouseleave": function ($event) {
        _vm.currentItem = _vm.$route.meta.slug;
      }
    }
  }, [_c('b-container', {
    staticClass: "cy-mainmenu__content",
    class: {
      'py-3': ['solutions'].includes(_vm.slug),
      'pl-0': ['products'].includes(_vm.slug)
    },
    attrs: {
      "fluid": ""
    }
  }, [_vm.slug === 'products' ? _c('b-row', {
    staticClass: "w-100 mx-0"
  }, [_c('b-col', {
    staticClass: "px-0 cy-bg-product-card",
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "position-relative d-block cy-text-nile-blue text-decoration-none p-4 pb-3 h-100"
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('h2', {
    staticClass: "cy-heading-4 cy-font-weight-600 cy-text-nile-blue"
  }, [_vm._v("\n              Cyber Fusion Center\n            ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-6 mb-2 cy-font-weight-400 text-dark"
  }, [_vm._v("\n              Stay ahead of threats with our cyber fusion solutions for threat\n              intelligence sharing and analysis, threat response, and security\n              automation.\n            ")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "cy-heading-6 cy-font-weight-600 cy-text-primary-blue text-decoration-underline-on-hover",
    attrs: {
      "to": _vm.getHyperLink('cfc-solutions')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('click', _vm.slug);
      }
    }
  }, [_vm._v("\n              Learn More\n              "), _c('b-img-lazy', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "src": require(`~/static/elements/right-arrow.svg`),
      "alt": "arrow"
    }
  })], 1)], 1)])]), _vm._v(" "), _vm._l(_vm.menu.children.slice(1, 4), function (menuCategory) {
    return _c('b-col', {
      key: menuCategory.title,
      staticClass: "py-3",
      attrs: {
        "cols": "3"
      }
    }, [menuCategory.title !== 'Threat Intelligence Ecosystem' ? _c('nuxt-link', {
      staticClass: "cy-heading-6 cy-font-weight-600 cy-text-default text-decoration-underline-on-hover",
      attrs: {
        "to": menuCategory.link
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.$emit('click', _vm.slug);
        }
      }
    }, [_c('span', {
      staticClass: "cy-heading-5 cy-font-weight-600 cy-line-height-25"
    }, [_vm._v("\n            " + _vm._s(menuCategory.title) + "\n          ")]), _vm._v(" "), _c('b-img-lazy', {
      staticClass: "ml-1",
      attrs: {
        "src": require(`~/static/elements/right-arrow-grey.svg`),
        "alt": "arrow"
      }
    })], 1) : _c('h2', {
      staticClass: "cy-heading-5 cy-font-weight-600 cy-line-height-25"
    }, [_vm._v("\n          " + _vm._s(menuCategory.title) + "\n        ")]), _vm._v(" "), _c('hr', {
      staticClass: "my-2 cy-menu-divider"
    }), _vm._v(" "), _c('b-row', _vm._l(menuCategory.children, function (menuItem) {
      return _c('b-col', {
        key: menuItem.slug,
        staticClass: "my-2",
        class: Object.keys('currentItem').length > 0 && _vm.currentItem === (menuItem === null || menuItem === void 0 ? void 0 : menuItem.slug) ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
        attrs: {
          "cols": "12"
        },
        on: {
          "mouseover": function ($event) {
            _vm.currentItem = menuItem.slug;
          }
        }
      }, [_c('nuxt-link', {
        staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
        attrs: {
          "to": menuItem.link
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.$emit('click', _vm.slug);
          }
        }
      }, [_c('b-row', {
        staticClass: "align-items-center"
      }, [_c('b-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('b-img-lazy', {
        staticClass: "cy-menu-iconsize",
        attrs: {
          "src": menuItem.icon_img_with_bg,
          "alt": menuItem.name,
          "fluid-grow": ""
        }
      })], 1), _vm._v(" "), _c('b-col', {
        staticClass: "pl-0",
        attrs: {
          "cols": "8"
        }
      }, [_c('h3', {
        staticClass: "cy-heading-6 cy-font-weight-600"
      }, [_vm._v("\n                    " + _vm._s(menuItem.name) + "\n                  ")])])], 1)], 1)], 1);
    }), 1)], 1);
  })], 2) : _vm._e(), _vm._v(" "), _vm.slug === 'partners' ? _c('b-row', [_c('b-col', {
    staticClass: "px-0 cy-bg-product-card",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "position-relative p-4 pb-3 h-100"
  }, [_c('h2', {
    staticClass: "cy-heading-4 cy-font-weight-600 cy-text-nile-blue"
  }, [_vm._v("\n            Partners & Integrations\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-6 cy-font-weight-400 cy-line-height-18 mb-2"
  }, [_vm._v("\n            Learn how our solutions seamlessly connect with other tools and\n            technology partners to fit your security needs.\n          ")])])]), _vm._v(" "), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    staticClass: "mx-0 py-3"
  }, [_vm._l(_vm.menu.children.slice(0, 5), function (menuItem) {
    return _c('b-col', {
      key: menuItem.slug,
      staticClass: "my-2",
      class: Object.keys('currentItem').length > 0 && _vm.currentItem === (menuItem === null || menuItem === void 0 ? void 0 : menuItem.slug) ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
      attrs: {
        "cols": "6"
      },
      on: {
        "mouseover": function ($event) {
          _vm.currentItem = menuItem.slug;
        }
      }
    }, [_c('nuxt-link', {
      staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
      attrs: {
        "to": menuItem.link
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.$emit('click', _vm.slug);
        }
      }
    }, [_c('b-row', {
      staticClass: "align-items-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('b-img-lazy', {
      staticClass: "cy-menu-iconsize",
      attrs: {
        "src": menuItem.icon,
        "alt": menuItem.name
      }
    })], 1), _vm._v(" "), _c('b-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "7"
      }
    }, [_c('h3', {
      staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
    }, [_vm._v("\n                    " + _vm._s(menuItem.name) + "\n                  ")])])], 1)], 1)], 1);
  }), _vm._v(" "), _c('b-col', {
    staticClass: "my-2",
    class: Object.keys('currentItem').length > 0 && _vm.currentItem === 'register-a-deal' ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
    attrs: {
      "cols": "6"
    },
    on: {
      "mouseover": function ($event) {
        _vm.currentItem = 'register-a-deal';
      }
    }
  }, [_c('a', {
    staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
    attrs: {
      "href": "https://go.cyware.com/registerdeal",
      "target": "_blank"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-menu-iconsize",
    attrs: {
      "src": "/integrations/header-icons/register.svg",
      "alt": "Register a deal"
    }
  })], 1), _vm._v(" "), _c('b-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "7"
    }
  }, [_c('h3', {
    staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
  }, [_vm._v("\n                    Register a Deal\n                  ")])])], 1)], 1)]), _vm._v(" "), _c('b-col', {
    staticClass: "my-2",
    class: Object.keys('currentItem').length > 0 && _vm.currentItem === 'cyware-one' ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
    attrs: {
      "cols": "6"
    },
    on: {
      "mouseover": function ($event) {
        _vm.currentItem = 'cyware-one';
      }
    }
  }, [_c('a', {
    staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
    attrs: {
      "href": "https://cyware.amp.vg/login",
      "target": "_blank"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-img-lazy', {
    staticClass: "cy-menu-iconsize",
    attrs: {
      "src": "/integrations/header-icons/cyware_one_login.svg",
      "alt": "CywareOne login"
    }
  })], 1), _vm._v(" "), _c('b-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "7"
    }
  }, [_c('h3', {
    staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
  }, [_vm._v("\n                    CywareOne Login\n                  ")])])], 1)], 1)])], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.slug === 'resources' ? _c('b-row', [_c('b-col', {
    staticClass: "px-0 cy-bg-product-card position-relative d-block cy-text-nile-blue text-decoration-none p-4 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('h2', {
    staticClass: "cy-heading-4 cy-font-weight-600 cy-text-nile-blue"
  }, [_vm._v("\n            Resources Library\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-6 cy-font-weight-400 text-dark cy-line-height-18 mb-2"
  }, [_vm._v("\n            Stay updated on the cyber threat landscape with free daily alerts,\n            the latest industry reports, security trends, and more.\n          ")])])]), _vm._v(" "), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    staticClass: "mx-0 py-3"
  }, _vm._l(_vm.menu.children.slice(0, 8), function (menuItem) {
    return _c('b-col', {
      key: menuItem.slug,
      staticClass: "my-2",
      class: Object.keys('currentItem').length > 0 && _vm.currentItem === (menuItem === null || menuItem === void 0 ? void 0 : menuItem.slug) ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
      attrs: {
        "cols": "6"
      },
      on: {
        "mouseover": function ($event) {
          _vm.currentItem = menuItem.slug;
        }
      }
    }, [menuItem.slug === 'videos' ? _c('a', {
      staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
      attrs: {
        "target": "_blank",
        "href": "https://www.brighttalk.com/channel/19925/?utm_source=Cyware&utm_medium=web&utm_campaign=19925"
      }
    }, [_c('b-row', {
      staticClass: "align-items-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('b-img-lazy', {
      staticClass: "cy-menu-iconsize",
      attrs: {
        "src": menuItem.icon,
        "alt": menuItem.name
      }
    })], 1), _vm._v(" "), _c('b-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "7"
      }
    }, [_c('h3', {
      staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
    }, [_vm._v("\n                    " + _vm._s(menuItem.name) + "\n                  ")])])], 1)], 1) : _c('nuxt-link', {
      staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
      attrs: {
        "to": menuItem.link
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.$emit('click', _vm.slug);
        }
      }
    }, [_c('b-row', {
      staticClass: "align-items-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('b-img-lazy', {
      staticClass: "cy-menu-iconsize",
      attrs: {
        "src": menuItem.icon,
        "alt": menuItem.name
      }
    })], 1), _vm._v(" "), _c('b-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "7"
      }
    }, [_c('h3', {
      staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
    }, [_vm._v("\n                    " + _vm._s(menuItem.name) + "\n                  ")])])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.slug === 'company' ? _c('b-row', [_c('b-col', {
    staticClass: "px-0 cy-bg-product-card position-relative d-block cy-text-nile-blue text-decoration-none p-4",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('h2', {
    staticClass: "cy-heading-4 cy-font-weight-600 cy-text-nile-blue"
  }, [_vm._v("\n            Contact Us\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "cy-heading-6 cy-font-weight-400 cy-line-height-18 mb-2"
  }, [_vm._v("\n            Get in touch with our team to learn more about our solutions and\n            how they can help your organization.\n          ")]), _vm._v(" "), _c('nuxt-link', {
    attrs: {
      "to": _vm.getHyperLink('contactus')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('click', _vm.slug);
      }
    }
  }, [_c('div', {
    staticClass: "cy-heading-6 cy-font-weight-600 cy-text-primary-blue mb-0"
  }, [_c('span', {
    staticClass: "text-decoration-underline-on-hover"
  }, [_vm._v("Get in Touch")]), _vm._v(" "), _c('b-img-lazy', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "src": require(`~/static/elements/right-arrow.svg`),
      "alt": "arrow"
    }
  })], 1)])], 1)]), _vm._v(" "), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('b-row', {
    staticClass: "mx-0 py-3"
  }, _vm._l(_vm.menu.children.slice(0, 5), function (menuItem) {
    return _c('b-col', {
      key: menuItem.slug,
      staticClass: "my-2",
      class: Object.keys('currentItem').length > 0 && _vm.currentItem === (menuItem === null || menuItem === void 0 ? void 0 : menuItem.slug) ? 'cy-mainmenu__content__item--hovered' : 'cy-mainmenu__content__item',
      attrs: {
        "cols": "6"
      },
      on: {
        "mouseover": function ($event) {
          _vm.currentItem = menuItem.slug;
        }
      }
    }, [_c('nuxt-link', {
      staticClass: "d-block text-decoration-none cy-text-nile-blue py-2",
      attrs: {
        "to": menuItem.link
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.$emit('click', _vm.slug);
        }
      }
    }, [_c('b-row', {
      staticClass: "align-items-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('b-img-lazy', {
      staticClass: "cy-menu-iconsize",
      attrs: {
        "src": menuItem.icon,
        "alt": menuItem.name
      }
    })], 1), _vm._v(" "), _c('b-col', {
      staticClass: "pl-0 d-flex align-items-center",
      attrs: {
        "cols": "8"
      }
    }, [_c('h3', {
      staticClass: "mb-0 cy-heading-6 cy-font-weight-600"
    }, [_vm._v("\n                    " + _vm._s(menuItem.name) + "\n                  ")]), _vm._v(" "), menuItem.careerTag ? _c('span', {
      staticClass: "px-2 cy-bg-product-card rounded cy-text-primary-blue cy-heading-7 cy-font-weight-600 text-decoration-none cy-mainmenu__hiring ml-1"
    }, [_vm._v("\n                    We’re Hiring\n                  ")]) : _vm._e()])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }