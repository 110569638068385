var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    ref: "navbar",
    staticClass: "cy-navbar fixed-top cy-header-white",
    class: [_vm.isBannerOpen && _vm.$route.path === '/' ? 'cy-mt-40' : ''],
    attrs: {
      "id": "cy-navbar"
    }
  }, [_c('b-container', {
    staticClass: "position-relative py-2 pr-md-3"
  }, [_vm.$device.isMobile && _vm.$device.isMobileOrTablet ? _c('cy-mobile-menu', {
    attrs: {
      "is-banner-open": _vm.isBannerOpen
    },
    on: {
      "toggle": function ($event) {
        _vm.activeMenu = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('div', [_c('b-navbar-brand', {
    staticClass: "mr-0 mr-md-3"
  }, [_c('nuxt-link', {
    staticClass: "d-inline-block",
    attrs: {
      "to": "/"
    }
  }, [_vm.homeLogo ? _c('b-img', {
    staticClass: "logo-height desktop-logo",
    attrs: {
      "id": "cyware-home-logo",
      "src": require(`~/static/logos/${_vm.homeLogo}.svg`),
      "fluid": "",
      "alt": "Cyware Logo",
      "width": 200,
      "height": 35
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _vm.$device.isDesktop ? _c('div', {
    staticClass: "d-flex flex-grow-1 align-items-center"
  }, [!_vm.showSearch ? _c('b-navbar-nav', [_vm._l(Object.keys(_vm.menus).filter(function (item) {
    return item !== 'extra' && item !== 'learn_more';
  }), function (item, index) {
    return _c('li', {
      key: index
    }, [_vm.menus[item].headerimage ? _c('nuxt-link', {
      staticClass: "nav-link ml-3",
      attrs: {
        "role": "button",
        "to": _vm.menus[item].link
      }
    }, [_c('b-img-lazy', {
      attrs: {
        "src": _vm.menus[item].headerimage,
        "alt": "cywareai"
      }
    })], 1) : _vm._e()], 1);
  }), _vm._v(" "), _vm._l(Object.keys(_vm.menus).filter(function (item) {
    return item !== 'extra' && item !== 'learn_more';
  }), function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "nav-item cy-font-weight-600 position-relative",
      on: {
        "mouseenter": function ($event) {
          return _vm.$emit('showBackdrop', true);
        },
        "mouseleave": function ($event) {
          return _vm.$emit('showBackdrop', false);
        }
      }
    }, [!_vm.menus[item].headerimage ? _c('a', {
      staticClass: "nav-link mt-1",
      attrs: {
        "role": "button"
      }
    }, [_vm._v("\n            " + _vm._s(_vm.menus[item].title) + "\n          ")]) : _vm._e(), _vm._v(" "), !_vm.menus[item].headerimage ? _c('cy-main-menu', {
      attrs: {
        "id": item,
        "slug": item,
        "menu": _vm.menus[item]
      },
      on: {
        "click": _vm.collapseNav,
        "footerSubscribe": function ($event) {
          return _vm.onClickSubscribe();
        }
      }
    }) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm._v(" "), _c('b-navbar-nav', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.onClickOutside,
      expression: "onClickOutside"
    }],
    staticClass: "cy-right-navbar ml-auto align-items-center"
  }, [!_vm.showSearch ? _c('li', {
    staticClass: "nav-item cy-font-weight-600 cy-text-primary-blue"
  }, [_c('nuxt-link', {
    staticClass: "nav-link ml-4 cy-home-header__blogs",
    attrs: {
      "to": _vm.getHyperLink('blog')
    }
  }, [_vm._v("\n            Blogs\n          ")])], 1) : _vm._e(), _vm._v(" "), _c('li', {
    staticClass: "nav-item cy-global-search d-inline-flex align-items-center"
  }, [_vm.showSearch ? _c('b-form-input', {
    ref: "globalSearch",
    attrs: {
      "placeholder": "Search for blogs, briefings, security guides, cyber news & more…"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnterSearch($event);
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "mx-2 cy-global-search__icon",
    class: _vm.showSearch ? 'cyicon-close cy-heading-6' : 'cyicon-search_icon cy-heading-4',
    on: {
      "click": function ($event) {
        return _vm.toggleSearch();
      }
    }
  })], 1), _vm._v(" "), _vm.isAuthenticated ? _c('li', {
    staticClass: "nav-item position-relative my-auto"
  }, [_c('a', {
    staticClass: "nav-link"
  }, [_vm.userDetails && _vm.userDetails.user_image_url ? _c('div', [_c('b-img-lazy', {
    staticClass: "nav-profile-image",
    attrs: {
      "src": _vm.userDetails.user_image_url
    }
  })], 1) : _c('div', {
    style: {
      border: '1px solid darkgray',
      borderRadius: '50px'
    }
  }, [_c('b-img-lazy', {
    staticClass: "nav-profile-image",
    attrs: {
      "src": require("static/dp-placeholder.svg")
    }
  })], 1)]), _vm._v(" "), _c('cy-profile-dropdown', {
    on: {
      "show": function ($event) {
        return _vm.showProfilePopup();
      }
    }
  })], 1) : _c('li', {
    staticClass: "nav-item cy-font-weight-600 my-auto cy-text-primary-blue"
  }, [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": `/login?source=enterprise${_vm.currentLocation ? '&next=' + _vm.currentLocation : ''}`
    }
  }, [_vm._v("Login")])]), _vm._v(" "), _c('li', {
    staticClass: "nav-item"
  }, [_c('nuxt-link', {
    staticClass: "text-decoration-none cy-text-white",
    attrs: {
      "to": _vm.getHyperLink('demo-page')
    }
  }, [_c('b-button', {
    staticClass: "cy-btn",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Request a Demo ")])], 1)], 1)])], 1) : _vm._e(), _vm._v(" "), _c('ul', {
    staticClass: "d-lg-none d-md-none list-unstyled mb-0"
  }, [_vm.isAuthenticated ? _c('li', {
    staticClass: "nav-item position-relative my-auto cy-pl-xs-0",
    class: {
      active: _vm.showProfileDropdown
    }
  }, [_c('a', {
    staticClass: "nav-link cy-pl-xs-0 cy-pr-xs-0 cy-pt-xs-0 cy-pb-xs-0",
    on: {
      "click": function ($event) {
        _vm.showProfileDropdown = !_vm.showProfileDropdown;
      }
    }
  }, [_vm.userDetails && _vm.userDetails.user_image_url ? _c('div', [_c('b-img-lazy', {
    staticClass: "nav-profile-image",
    attrs: {
      "src": _vm.userDetails.user_image_url
    }
  })], 1) : _c('div', {
    style: {
      border: '1px solid darkgray',
      borderRadius: '50px'
    }
  }, [_c('b-img-lazy', {
    staticClass: "nav-profile-image",
    attrs: {
      "src": "/dp-placeholder.svg"
    }
  })], 1)]), _vm._v(" "), _c('cy-profile-dropdown', {
    on: {
      "show": function ($event) {
        return _vm.showProfilePopup();
      },
      "close": function ($event) {
        _vm.showProfileDropdown = false;
      }
    },
    nativeOn: {
      "click": function ($event) {
        _vm.showProfileDropdown = false;
      }
    }
  })], 1) : _c('li', {
    staticClass: "nav-item cy-font-weight-600 my-auto cy-pl-xs-0 cy-text-primary-blue"
  }, [_c('a', {
    staticClass: "nav-link cy-pl-xs-0 cy-pr-xs-0 cy-pt-xs-0 cy-pb-xs-0 cy-mobile-menu__toggler",
    attrs: {
      "href": `/login?source=enterprise${_vm.currentLocation ? '&next=' + _vm.currentLocation : ''}`
    }
  }, [_vm._v("Login")])])])], 1), _vm._v(" "), _vm.showProfileModal && _vm.isAuthenticated ? _c('cy-profile', {
    ref: "cyprofile"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }