<template>
  <div
    class="cy-mainmenu"
    :style="computedStyle"
    @mouseleave="currentItem = $route.meta.slug"
  >
    <b-container
      fluid
      class="cy-mainmenu__content"
      :class="{
        'py-3': ['solutions'].includes(slug),
        'pl-0': ['products'].includes(slug)
      }"
    >
      <b-row v-if="slug === 'products'" class="w-100 mx-0">
        <b-col cols="3" class="px-0 cy-bg-product-card">
          <div
            class="position-relative d-block cy-text-nile-blue text-decoration-none p-4 pb-3 h-100"
          >
            <div class="position-relative">
              <h2 class="cy-heading-4 cy-font-weight-600 cy-text-nile-blue">
                Cyber Fusion Center
              </h2>
              <p class="cy-heading-6 mb-2 cy-font-weight-400 text-dark">
                Stay ahead of threats with our cyber fusion solutions for threat
                intelligence sharing and analysis, threat response, and security
                automation.
              </p>
              <nuxt-link
                :to="getHyperLink('cfc-solutions')"
                class="cy-heading-6 cy-font-weight-600 cy-text-primary-blue text-decoration-underline-on-hover"
                @click.native="$emit('click', slug)"
              >
                Learn More
                <b-img-lazy
                  style="width: 10%"
                  :src="require(`~/static/elements/right-arrow.svg`)"
                  alt="arrow"
                >
                </b-img-lazy>
              </nuxt-link>
            </div>
          </div>
        </b-col>
        <b-col
          v-for="menuCategory in menu.children.slice(1, 4)"
          :key="menuCategory.title"
          class="py-3"
          cols="3"
        >
          <nuxt-link
            v-if="menuCategory.title !== 'Threat Intelligence Ecosystem'"
            :to="menuCategory.link"
            class="cy-heading-6 cy-font-weight-600 cy-text-default text-decoration-underline-on-hover"
            @click.native="$emit('click', slug)"
          >
            <span class="cy-heading-5 cy-font-weight-600 cy-line-height-25">
              {{ menuCategory.title }}
            </span>
            <b-img-lazy
              :src="require(`~/static/elements/right-arrow-grey.svg`)"
              alt="arrow"
              class="ml-1"
            >
            </b-img-lazy>
          </nuxt-link>
          <h2 v-else class="cy-heading-5 cy-font-weight-600 cy-line-height-25">
            {{ menuCategory.title }}
          </h2>
          <hr class="my-2 cy-menu-divider" />
          <b-row>
            <b-col
              v-for="menuItem in menuCategory.children"
              :key="menuItem.slug"
              cols="12"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuItem?.slug
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="my-2"
              @mouseover="currentItem = menuItem.slug"
            >
              <nuxt-link
                :to="menuItem.link"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                @click.native="$emit('click', slug)"
              >
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <b-img-lazy
                      :src="menuItem.icon_img_with_bg"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                      fluid-grow
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="8" class="pl-0">
                    <h3 class="cy-heading-6 cy-font-weight-600">
                      {{ menuItem.name }}
                    </h3>
                    <!-- <p class="m-0 cy-heading-7 cy-text-grey cy-font-weight-600">
                      {{ menuItem.short_title }}
                    </p> -->
                  </b-col>
                </b-row>
              </nuxt-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- <b-row v-if="slug === 'solutions'" align-v="stretch" class="w-100 mx-0">
        <b-col
          v-for="menuCategory in menu.children"
          :key="menuCategory.title"
          cols="4"
        >
          <b-row
            v-if="menuCategory.link"
            :class="subCategory ? 'cy-mainmenu__solutions--backdrop' : ''"
          >
            <b-col cols="12">
              <h2
                class="cy-heading-5 cy-font-weight-600 cy-line-height-25 w-75"
              >
                {{ menuCategory.title }}
              </h2>
              <nuxt-link
                :to="menuCategory.link"
                class="cy-heading-6 cy-font-weight-600 text-decoration-none cy-text-primary-blue"
                @click.native="$emit('click', slug)"
              >
                View all
                <b-img-lazy
                  style="width: 10%"
                  :src="require(`~/static/elements/right-arrow.svg`)"
                  alt="arrow"
                >
                </b-img-lazy>
              </nuxt-link>
              <hr class="mt-3 cy-menu-divider" />
            </b-col>
            <b-col
              v-for="menuItem in menuCategory.children.slice(1, 5)"
              :key="menuItem.slug"
              cols="12"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuItem?.slug
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="mb-2"
              @mouseover="currentItem = menuItem.slug"
            >
              <nuxt-link
                :to="menuItem.link"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                @click.native="$emit('click', slug)"
              >
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <b-img-lazy
                      :src="menuItem.icon"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="8" class="pl-0">
                    <h3 class="cy-heading-6 cy-font-weight-600 mb-0">
                      {{ menuItem.name }}
                    </h3>
                  </b-col>
                </b-row>
              </nuxt-link>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <h2
                class="cy-heading-5 cy-font-weight-600 cy-line-height-25 w-75"
              >
                {{ menuCategory.title }}
              </h2>

              <p
                v-if="menuCategory.subtitle"
                class="mb-0 cy-heading-6 cy-text-grey cy-font-weight-600"
                style="padding-bottom: 3px"
              >
                {{ menuCategory.subtitle }}
              </p>
              <hr class="mt-3 cy-menu-divider" />
            </b-col>
            <b-col
              v-for="menuSubCat in menuCategory.children"
              :key="menuSubCat.title"
              cols="12"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuSubCat
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="cy-mainmenu__category mb-2"
              @mouseover="currentItem = menuSubCat"
              @mouseenter="subCategory = menuSubCat.title"
              @mouseleave="subCategory = null"
            >
              <div class="d-block text-decoration-none cy-text-nile-blue py-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <b-img-lazy
                      :src="menuSubCat.icon"
                      :alt="menuSubCat.title"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col
                    cols="8"
                    class="pl-0 d-flex align-items-center justify-content-between"
                  >
                    <h3 class="cy-heading-6 cy-font-weight-600 mb-0">
                      {{ menuSubCat.title }}
                    </h3>
                    <i
                      class="cyicon-chevron-up cy-rotate-90"
                      style="font-size: 7px"
                    />
                  </b-col>
                </b-row>
              </div>
              <div
                class="cy-mainmenu__submenu"
                :style="computedSubMenuStyles(menuSubCat.title)"
              >
                <b-row class="cy-mainmenu__submenu-item mx-0">
                  <b-col
                    v-for="menuItem in menuSubCat.children"
                    :key="menuItem.slug"
                    cols="12"
                  >
                    <nuxt-link
                      :to="menuItem.link ? menuItem.link : menuItem.redirect"
                      class="d-block text-decoration-none cy-text-nile-blue py-3"
                      @click.native="$emit('click', slug)"
                    >
                      <b-row class="align-items-center">
                        <b-col cols="3">
                          <b-img-lazy
                            :src="menuItem.icon"
                            :alt="menuItem.name"
                            fluid-grow
                          >
                          </b-img-lazy>
                        </b-col>
                        <b-col cols="9" class="pl-0">
                          <h3 class="cy-heading-6 mb-0 cy-line-height-20">
                            <span class="cy-mainmenu__submenu-item-title">
                              {{ menuItem.name }}
                            </span>
                          </h3>
                        </b-col>
                      </b-row>
                    </nuxt-link>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->

      <b-row v-if="slug === 'partners'">
        <b-col cols="4" class="px-0 cy-bg-product-card">
          <div class="position-relative p-4 pb-3 h-100">
            <h2 class="cy-heading-4 cy-font-weight-600 cy-text-nile-blue">
              Partners & Integrations
            </h2>
            <p class="cy-heading-6 cy-font-weight-400 cy-line-height-18 mb-2">
              Learn how our solutions seamlessly connect with other tools and
              technology partners to fit your security needs.
            </p>
          </div>
        </b-col>
        <b-col cols="8">
          <b-row class="mx-0 py-3">
            <b-col
              v-for="menuItem in menu.children.slice(0, 5)"
              :key="menuItem.slug"
              cols="6"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuItem?.slug
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="my-2"
              @mouseover="currentItem = menuItem.slug"
            >
              <nuxt-link
                :to="menuItem.link"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                @click.native="$emit('click', slug)"
              >
                <b-row class="align-items-center">
                  <b-col cols="5">
                    <b-img-lazy
                      :src="menuItem.icon"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="7" class="pl-0">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      {{ menuItem.name }}
                    </h3>
                  </b-col>
                </b-row>
              </nuxt-link>
            </b-col>
            <b-col
              cols="6"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === 'register-a-deal'
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="my-2"
              @mouseover="currentItem = 'register-a-deal'"
            >
              <a
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                href="https://go.cyware.com/registerdeal"
                target="_blank"
                ><b-row class="align-items-center">
                  <b-col cols="5">
                    <b-img-lazy
                      src="/integrations/header-icons/register.svg"
                      alt="Register a deal"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="7" class="pl-0">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      Register a Deal
                    </h3>
                  </b-col>
                </b-row></a
              >
            </b-col>
            <b-col
              cols="6"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === 'cyware-one'
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="my-2"
              @mouseover="currentItem = 'cyware-one'"
            >
              <a
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                href="https://cyware.amp.vg/login"
                target="_blank"
                ><b-row class="align-items-center">
                  <b-col cols="5">
                    <b-img-lazy
                      src="/integrations/header-icons/cyware_one_login.svg"
                      alt="CywareOne login"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="7" class="pl-0">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      CywareOne Login
                    </h3>
                  </b-col>
                </b-row></a
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="slug === 'resources'">
        <b-col
          cols="4"
          class="px-0 cy-bg-product-card position-relative d-block cy-text-nile-blue text-decoration-none p-4 pb-3"
        >
          <div class="position-relative">
            <h2 class="cy-heading-4 cy-font-weight-600 cy-text-nile-blue">
              Resources Library
            </h2>
            <p
              class="cy-heading-6 cy-font-weight-400 text-dark cy-line-height-18 mb-2"
            >
              Stay updated on the cyber threat landscape with free daily alerts,
              the latest industry reports, security trends, and more.
            </p>
          </div>
        </b-col>
        <b-col cols="8">
          <b-row class="mx-0 py-3">
            <b-col
              v-for="menuItem in menu.children.slice(0, 8)"
              :key="menuItem.slug"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuItem?.slug
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              cols="6"
              class="my-2"
              @mouseover="currentItem = menuItem.slug"
            >
              <a
                v-if="menuItem.slug === 'videos'"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                target="_blank"
                href="https://www.brighttalk.com/channel/19925/?utm_source=Cyware&utm_medium=web&utm_campaign=19925"
              >
                <b-row class="align-items-center">
                  <b-col cols="5">
                    <b-img-lazy
                      :src="menuItem.icon"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="7" class="pl-0">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      {{ menuItem.name }}
                    </h3>
                  </b-col>
                </b-row>
              </a>
              <nuxt-link
                v-else
                :to="menuItem.link"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                @click.native="$emit('click', slug)"
              >
                <b-row class="align-items-center">
                  <b-col cols="5">
                    <b-img-lazy
                      :src="menuItem.icon"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="7" class="pl-0">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      {{ menuItem.name }}
                    </h3>
                  </b-col>
                </b-row>
              </nuxt-link>
            </b-col>
          </b-row>
          <!-- <b-row class="cy-bg-product-card mx-3 p-3 mb-4">
            <b-col cols="7">
              <p class="mb-0 cy-heading-5 cy-font-weight-400">
                Get these resources directly in your email and stay updated
              </p>
            </b-col>
            <b-col cols="5" class="text-center">
              <b-button
                v-b-modal.subscriber-modal
                class="cy-btn cy-btn--outline-primary cy-font-weight-600 align-items-center"
                @click="$emit('footerSubscribe')"
              >
                Subscribe
                <i class="cyicon-arrow-right cy-heading-6"></i>
              </b-button>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>

      <b-row v-if="slug === 'company'">
        <b-col
          cols="4"
          class="px-0 cy-bg-product-card position-relative d-block cy-text-nile-blue text-decoration-none p-4"
        >
          <div class="position-relative">
            <h2 class="cy-heading-4 cy-font-weight-600 cy-text-nile-blue">
              Contact Us
            </h2>
            <p class="cy-heading-6 cy-font-weight-400 cy-line-height-18 mb-2">
              Get in touch with our team to learn more about our solutions and
              how they can help your organization.
            </p>
            <nuxt-link
              :to="getHyperLink('contactus')"
              class=""
              @click.native="$emit('click', slug)"
            >
              <div
                class="cy-heading-6 cy-font-weight-600 cy-text-primary-blue mb-0"
              >
                <span class="text-decoration-underline-on-hover"
                  >Get in Touch</span
                >
                <b-img-lazy
                  style="width: 10%"
                  :src="require(`~/static/elements/right-arrow.svg`)"
                  alt="arrow"
                >
                </b-img-lazy>
              </div>
            </nuxt-link>
          </div>
        </b-col>
        <b-col cols="8">
          <b-row class="mx-0 py-3">
            <b-col
              v-for="menuItem in menu.children.slice(0, 5)"
              :key="menuItem.slug"
              cols="6"
              :class="
                Object.keys('currentItem').length > 0 &&
                currentItem === menuItem?.slug
                  ? 'cy-mainmenu__content__item--hovered'
                  : 'cy-mainmenu__content__item'
              "
              class="my-2"
              @mouseover="currentItem = menuItem.slug"
            >
              <nuxt-link
                :to="menuItem.link"
                class="d-block text-decoration-none cy-text-nile-blue py-2"
                @click.native="$emit('click', slug)"
              >
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <b-img-lazy
                      :src="menuItem.icon"
                      :alt="menuItem.name"
                      class="cy-menu-iconsize"
                    >
                    </b-img-lazy>
                  </b-col>
                  <b-col cols="8" class="pl-0 d-flex align-items-center">
                    <h3 class="mb-0 cy-heading-6 cy-font-weight-600">
                      {{ menuItem.name }}
                    </h3>
                    <span
                      v-if="menuItem.careerTag"
                      class="px-2 cy-bg-product-card rounded cy-text-primary-blue cy-heading-7 cy-font-weight-600 text-decoration-none cy-mainmenu__hiring ml-1"
                    >
                      We’re Hiring
                    </span>
                  </b-col>
                </b-row>
              </nuxt-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {
    slug: {
      type: String,
      default: () => null
    },
    menu: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentItem: null,
      subCategory: null
    }
  },
  computed: {
    computedStyle() {
      switch (this.slug) {
        case 'integrations': {
          return 'width: 580px;'
        }
        case 'partners': {
          return 'width: 600px;'
        }
        case 'company': {
          return 'width: 650px;left: -330px;'
        }
        case 'resources': {
          return 'width: 610px;left: -180px;'
        }
        case 'solutions': {
          return 'width: 750px;left: -180px;'
        }
        default: {
          return ''
        }
      }
    }
  },
  watch: {
    $route(to) {
      this.currentItem = to.meta.slug
    }
  },
  mounted() {
    this.currentItem = this.$route.meta.slug
  },
  methods: {
    onClick(slug) {
      this.$emit('click', slug)
      EventBus.$emit('focus-subscribe')
    },
    computedSubMenuStyles(submenu) {
      switch (submenu) {
        case 'ISACs/ISAOs/CERTs': {
          return 'top: auto;bottom: 0px;'
        }
        case 'ISAC/ISAO/CERT Members': {
          return 'top: auto;bottom: 0px;'
        }
        default: {
          return ''
        }
      }
    }
  }
}
</script>
