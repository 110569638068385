import Vue from 'vue'
export const state = () => ({
  list: {}
})

export const mutations = {
  resetAuthentication(state) {
    this.$cookies.removeAll()
    delete this.state.list.USER_PROFILE
  },
  makeData(state, item) {
    Vue.set(state.list, item.reference, item.data)
  },
  pushData(state, item) {
    const allData = state.list[item.reference]
    item?.data?.results.forEach((i) => {
      allData.results.push(i)
    })
    allData.link.next = item?.data?.link?.next
    Vue.set(state.list, item.reference, allData)
  }
}

export const actions = {
  async GET({ commit }, item) {
    const commiter = item.push ? 'pushData' : 'makeData'
    await commit(commiter, {
      reference: item.as || item.reference,
      data: item.data
    })
  },

  async nuxtServerInit({ commit }, { $sentry }, context) {
    try {
      const authToken = this.$cookies.get('auth._token.local')

      if (authToken) {
        this.$axios.setHeader('Authorization', authToken)
        this.$axios.setHeader('cookie', this.$cookies.getAll())
        await this.$axios
          .$get(
            `${process.env.BLOG_API_URL}${process.env.API_WRAPPER_PREFIX}/user-profile-details`
          )
          .then((response) => {
            commit('makeData', { reference: 'USER_PROFILE', data: response })
          })
          .catch((error) => {
            if (error.response.data) {
              commit('resetAuthentication')
            }
          })
      }
    } catch (error) {
      $sentry.captureException(error)
    }
  }
}
